import React, { useState, useEffect, useContext, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../context/Enviroment";
import axios from "axios";

const CompanyInfo = () => {
  let { isLang, setIsLang } = useContext(VendersContext);
  const toast = useRef(null);

  const [settings, setSettings] = useState({
    app_name: {
      en: "",
      de: "",
      ar: ""
    }
  });
  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${BASE_URL}/dashboard/settings`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = await response.json();
      setSettings(data.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleReviewsEnabledChange = e => {
    const value = e.target.checked ? "1" : "0";
    setSettings(prevSettings => ({
      ...prevSettings,
      comments_enabled: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("userToken");
      const formData = {
        app_email: settings.app_email,
        app_phone: settings.app_phone,
        playstore: settings.playstore,
        appstore: settings.appstore,
        facebook_url: settings.facebook_url,
        linkdin_url: settings.linkdin_url,
        instagram_url: settings.instagram_url,
        whatsapp_url: settings.whatsapp_url,
        comments_enabled: settings.comments_enabled,
        twitter_url: settings.twitter_url,
        comments_enabled: settings.comments_enabled,
        app_name: {
          de: settings.app_name_de,
          en: settings.app_name_en,
          ar: settings.app_name_ar
        }
      };

      const response = await axios.post(`${BASE_URL}/dashboard/settings/update-info`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      //console.log("Data sent successfully:", response.data);
      // Display success message if needed
      toast.current.show({ severity: "success", summary: "Success", detail: "Data saved successfully" });
    } catch (error) {
      console.error("Failed to send data:", error);
      // Display error message if needed
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to save data" });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Toast ref={toast} />

      <h5>{isLang === "en" ? "General Informations:" : "المعلومات العامة"} </h5>
      <TabView>
        <TabPanel header={isLang === "en" ? "English" : "اللغة الانجليزية"}>
          <label className="h6 mt-3 info-label" htmlFor="app_name_de">
            {isLang === "en" ? "Name" : "الاسم"}{" "}
          </label>
          <input
            className="form-control"
            type="text"
            id="app_name_en"
            name="app_name_en"
            value={settings.app_name_en}
            onChange={handleInputChange}
          />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Germany" : "اللغة الالمانية"}>
          <label className="h6 mt-3 info-label" htmlFor="app_name_en">
            {isLang === "en" ? "Name" : "الاسم"}{" "}
          </label>
          <input
            className="form-control"
            type="text"
            id="app_name_de"
            name="app_name_de"
            value={settings.app_name_de}
            onChange={handleInputChange}
          />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Arabic" : "اللغة العربية"}>
          <label className="h6 mt-3 info-label" htmlFor="app_name_ar">
            {isLang === "en" ? "Name" : "الاسم"}
          </label>
          <input
            className="form-control"
            type="text"
            id="app_name_ar"
            name="app_name_ar"
            value={settings.app_name_ar}
            onChange={handleInputChange}
          />
        </TabPanel>
      </TabView>
      <div className="mx-3">
        <label className="h6 mt-3 info-label" htmlFor="app_email">
          {isLang === "en" ? "Email" : "الايميل"}
        </label>
        <input
          className="form-control"
          type="email"
          id="app_email"
          name="app_email"
          value={settings.app_email}
          onChange={handleInputChange}
        />

        <label className="h6 mt-3 info-label" htmlFor="app_phone">
          {isLang === "en" ? "Phone" : "الهاتف"}
        </label>
        <input
          className="form-control"
          type="tel"
          id="app_phone"
          name="app_phone"
          value={settings.app_phone}
          onChange={handleInputChange}
        />
        <h5 className="pt-4"> {isLang === "en" ? "Social Media URLS:" : "روابط التواصل الاجتماعي:"}</h5>
        <div className="mb-3">
          <label className="h6 mt-3 info-label" htmlFor="facebook_url">
            {" "}
            {isLang === "en" ? "Facebook" : "الفيسبوك"}{" "}
          </label>
          <input
            className="form-control"
            id="facebook_url"
            name="facebook_url"
            value={settings.facebook_url}
            onChange={handleInputChange}
          />

          <label className="h6 mt-3 info-label" htmlFor="linkdin_url">
            {isLang === "en" ? "LinkedIn" : "لينكدان"}{" "}
          </label>
          <input
            className="form-control"
            id="linkdin_url"
            name="linkdin_url"
            value={settings.linkdin_url}
            onChange={handleInputChange}
          />

          <label className="h6 mt-3 info-label" htmlFor="instagram_url">
            {isLang === "en" ? "Instagram" : "انستجرام"}{" "}
          </label>
          <input
            className="form-control"
            id="instagram_url"
            name="instagram_url"
            value={settings.instagram_url}
            onChange={handleInputChange}
          />

          <label className="h6 mt-3 info-label" htmlFor="whatsapp_url">
            {isLang === "en" ? "WhatsApp" : "واتساب"}{" "}
          </label>
          <input
            className="form-control"
            id="whatsapp_url"
            name="whatsapp_url"
            value={settings.whatsapp_url}
            onChange={handleInputChange}
          />

          <label className="h6 mt-3 info-label" htmlFor="twitter_url">
            {isLang === "en" ? "Twitter" : "تويتر"}{" "}
          </label>
          <input
            className="form-control"
            id="twitter_url"
            name="twitter_url"
            value={settings.twitter_url}
            onChange={handleInputChange}
          />
          <label className="h6 mt-3 info-label" htmlFor="playstore">
            {isLang === "en" ? "Play Store" : "جوجل بلاي"}{" "}
          </label>
          <input
            className="form-control"
            id="playstore"
            name="playstore"
            value={settings.playstore}
            onChange={handleInputChange}
          />
          <label className="h6 mt-3 info-label" htmlFor="appstore">
            {isLang === "en" ? "Apple Store" : "ابل ستور"}{" "}
          </label>
          <input
            className="form-control"
            id="appstore"
            name="appstore"
            value={settings.appstore}
            onChange={handleInputChange}
          />
        </div>

        <h5 className="pt-2">{isLang === "en" ? "Other Settings:" : "اعدادات اخرى:"} </h5>
        <div className="card w-50 mb-3 p-2 justify-content-between mt-3 d-flex flex-row align-items-center">
          <h6 className="ml-2">{isLang === "en" ? " Allow users to add comments" : " السماح للعملاء بالتعليق على المنتجات"}</h6>
          <Checkbox
            className="border-3 checkbox-allow"
            value={settings?.comments_enabled}
            checked={settings.comments_enabled === "1"}
            onChange={handleReviewsEnabledChange}
          />
        </div>
        <div className="w-1000 justify-content-center text-center mt-4 d-flex ">
          <button type="submit" className="btn btn-primary justify-content-center static-btn w-50 ">
            {isLang === "en" ? "Save" : "حفظ"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CompanyInfo;
