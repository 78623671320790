import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import { useContext } from "react";
import { VendersContext } from "../../context/Store";
import { BASE_URL } from "../../context/Enviroment";
import Loading from "../../Components/Loading/Loading";

const Brands = props => {
  const [currentpage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  let { isLang } = useContext(VendersContext);

  const [products, setProducts] = useState([]);

  async function getBrands(page) {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("userToken");
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get(`${BASE_URL}/dashboard/brands?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.data);
      //console.log(data.data.data)
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }


  async function searchBrands(e, page) {
    const token = localStorage.getItem("userToken");

    if (e.target.value) {
      let { data } = await axios.get(`${BASE_URL}/dashboard/brands`, {
        params: {
          sort_by: 'name',
          page: 1,
          search: e.target.value, 
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setProducts(data.data.data);
    } else {
      getBrands();
    }
  }

  useEffect(() => {
    getBrands(1);
  }, []);

  let emptyProduct = {
    name: ""
  };

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nameTouched, setNameTouched] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);
  const [isAddingBrand, setIsAddingBrand] = useState(false);

  const openNew = () => {
    setIsAddingBrand(true);
    setProduct(emptyProduct);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const onSave = async product => {
    try {
      const token = localStorage.getItem("userToken");
  
      const { id, ...productData } = product;
  
      const response = await axios.post(`${BASE_URL}/dashboard/brands/update/${id}`, productData, {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        }
      });
      const data = response.data;
      getBrands(currentpage); 
      setProductDialog(false); 
      toast.current.show({ severity: "success", summary: "Success", detail: "Brand updated successfully", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update brand", life: 3000 });
    }
  };
  
  const editProduct = product => {
    setProduct({ id: product.id, name: product.name });
    setIsAddingBrand(false);
    setProductDialog(true);
  };

  const findIndexById = id => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteBrandDialoge = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = product => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      const token = localStorage.getItem("userToken");
  
      await axios.delete(`${BASE_URL}/dashboard/brands/delete/${product.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      const updatedProducts = products.filter(val => val.id !== product.id);
      setProducts(updatedProducts);
      setProduct(emptyProduct);
      setDeleteProductDialog(false);
  
      toast.current.show({ severity: "success", summary: "Successful", detail: "Brand Deleted", life: 3000 });
    } catch (error) {
      ////console.log(error.response);
      setDeleteProductDialog(false);
      toast.current.show({ severity: "error", summary: "Error", detail: "This brand is related to some products", life: 4000 });
    }
  };
  
  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    try {
      const token = localStorage.getItem("userToken");
      
      // Extract the IDs of selected products
      const selectedProductIds = selectedProducts.map(product => product.id);
  
      // Create the request data with the brand_ids
      const requestData = {
        brand_ids: selectedProductIds
      };
  
      // Send the DELETE request with the requestData in the request body
      await axios.delete(`${BASE_URL}/dashboard/brands/delete-selected`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: requestData // Sending brand_ids in the request body
      });
  
      // Update the products list
      const updatedProducts = products.filter(val => !selectedProductIds.includes(val.id));
      setProducts(updatedProducts);
  
      setSelectedProducts(null);
      setDeleteProductsDialog(false);
  
      toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete products", life: 3000 });
    }
  };
  
  

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);

    if (name === "name") {
      setNameTouched(true);
    }
  };



  const actionBodyTemplate = rowData => {
    return (
      <div className="d-flex gap-4">
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
      </div>
    );
  };

  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{isLang === "en" ? "Manage Brands" : "إدارة العلامات التجارية"}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          onChange={searchBrands}
          type="search"
          onInput={e => searchBrands(e.target.value)}
          placeholder={isLang === "en" ? "Search..." : "البحث"}
        />
      </span>
    </div>
  );
  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        <Button
          label={isLang === "en" ? "Add Brand" : "اضافة ماركة"}
          icon="pi pi-plus"
          severity="success"
          className="rounded-3"
          onClick={openNew}
        />
        <Button
          label={isLang === "en" ? "Delete" : " حذف"}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          className={selectedProducts && selectedProducts.length ? "rounded-3" : "p-disabled rounded-3"}
        />
      </div>
    );
  };

  const saveBrand = async brand => {
    const token = localStorage.getItem("userToken");

    try {
      const response = await fetch(`${BASE_URL}/dashboard/brands/create`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(brand)
      });
      const data = await response.json();
      // handle response data
      getBrands();
      setProducts([...products, data]); // add new brand to products state
      setProductDialog(false); // close dialog
      toast.current.show({ severity: "success", summary: "Success", detail: "Brand added successfully", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add brand", life: 3000 });
    }
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };
  const handleSaveClick = () => {
    if (isAddingBrand) {
      saveBrand(product);
    } else {
      onSave(product);
    }
    setIsAddingBrand(false);
    setProduct(emptyProduct);
    setProductDialog(false);
  };
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = event => {
    const newPage = event.page + 1; 
    setCurrentPage(newPage);
    getBrands(newPage);
    setFirst(event.first);
    setRows(event.rows);
  };
  const productDialogFooter = (
    <div>
      <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={handleSaveClick} />
      <Button
        label={isLang === "en" ? "Cancel" : "إلغاء "}
        icon="pi pi-times"
        onClick={hideDialog}
        className="p-button-secondary"
      />
    </div>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" className="p-button-secondary" outlined onClick={hideDeleteProductDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" className="p-button-secondary" outlined onClick={hideDeleteProductsDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="mx-4 mt-2">{isLang === "en" ? "Manage Brands" : "إدارة العلامات التجارية"}</h3>
      <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <Toolbar dir={isLang == "en" ? "ltr" : "rtl"} className=" m-2" left={leftToolbarTemplate} />
        <span className="p-input-icon-left mx-4">
          <i className="pi pi-search" />
          <InputText
            onChange={searchBrands}
            type="search"
            onInput={e => searchBrands(e.target.value)}
            placeholder={isLang === "en" ? "Search..." : "البحث"}
          />
        </span>
      </div>
      <Toast ref={toast} />
      <div className="card mx-4 p-2 rounded-4 ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DataTable
              stripedRows
              dir={isLang == "en" ? "ltr" : "rtl"}
              value={products}
              selection={selectedProducts}
              onSelectionChange={e => setSelectedProducts(e.value)}
              dataKey="id"
              currentpagereporttemplate="Showing {first} to {last} of {totalRecords} products"
              globalFilter={globalFilter}
            >
              <Column selectionMode="multiple" className="px-3" exportable={true} />
              <Column field="id" header={isLang === "en" ? "ID" : "الرقم"} style={{ minWidth: "12rem" }} />
              <Column field="name" header={isLang === "en" ? "Brand Name" : "العلامة التجارية"} style={{ minWidth: "16rem" }} />
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "12rem" }} />
            </DataTable>
            <Paginator
              ref={dtt.current}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
              currentpage={currentpage - 1}
            />
          </>
        )}
      </div>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={productDialog}
        style={{ width: "34rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={isLang === "en" ? "Add Brand " : "إضافة ماركة"}
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold h6">
            {isLang === "en" ? "Name" : " الاسم"}
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={e => onInputChange(e, "name")}
            required
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && <small className="p-error">Name is required.</small>}
        </div>
      </Dialog>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={isLang === "en" ? "Confirm" : "تأكيد"}
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content ">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              {isLang === "en" ? "Are you sure you want to delete" : " هل أنت متأكد أنك تريد حذف"} <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={isLang === "en" ? "Confirm" : "تأكيد"}
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteBrandDialoge}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              {isLang === "en"
                ? "Are you sure you want to delete the selected products?"
                : "   هل أنت متأكد أنك تريد حذف المنتجات المحددة؟"}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Brands;
