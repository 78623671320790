import Vendor from "../Layout/Vendor";
import Navber from "./../Components/Navber/Navber";
import Error from "./../Components/NotFound/Error";
import Sildebar from "./../Components/Sidebar/Sildebar";
import Auth from "./../Layout/Auth";
import Contact from "./../Pages/Contact/Contact";
import Dashboard from "./../Pages/Dashbord/Dashbord";
import Login from "./../Pages/auth/Login/Login";
import Home from "./../Pages/Home/Home";
import SettingForm from "../Pages/Settings/Settings";
import StaticPage from "../Pages/Settings/StaticPage/StaticPage";
import Admins from "../Pages/Admins/Admins";
import ProductsReport from "../Pages/Reports/ProductsReports";
import ScannedReport from "../Pages/Reports/ScannedReport";
import UsersTable from "../Pages/Reports/UsersReport";
import ProductForm from "../Pages/Inventory/AddProduct";
import Brands from "../Pages/Inventory/Brands";
import Categories from "../Pages/Inventory/Categories";
import Products from "../Pages/Inventory/Products";
import Stores from "../Pages/Inventory/Stores";
import suggestedForm from "../Pages/Inventory/ViewSuggested";
import EditForm from "../Pages/Inventory/EditProduct";
import Profile from "../Pages/Profile/Profile";
import SuggestedProducts from "../Pages/Inventory/SuggestedProducts";
import Reviews from "../Pages/Reviews/Reviews";
import Complaints from "../Pages/Complaints/Complaints";
const Component = {
  // Layout
  Vendor,
  Auth,
  Dashboard,
  Contact,
  Login,
  Navber,
  Sildebar,
  Error,
  Profile,
  Home,
  SettingForm,
  StaticPage,
  Admins,
  ProductsReport,
  ScannedReport,
  UsersTable,
  ProductForm,
  Brands,
  Categories,
  Products,
  Stores,
  suggestedForm,
  EditForm,
  SuggestedProducts,
  Reviews,
  Complaints,
};

export default Component;
