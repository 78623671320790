import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { VendersContext } from "../../context/Store";
import { useContext } from "react";
import { BASE_URL } from "../../context/Enviroment";

const Reviews = props => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [products, setProducts] = useState([]);

  async function getReviews(page) {
    try {
      const token = localStorage.getItem("userToken");

      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get(`${BASE_URL}/dashboard/comments?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.data);
      ////console.log(data.data.reviews);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getReviews(1);
  }, []);

  let emptyProduct = {
    name: ""
  };

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);
  const [isAddingBrand, setIsAddingBrand] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const PopupMessage = ({ message, onHide }) => {
    return (
      <Dialog
        header={isLang === "en" ? "Full Message" : "الرسالة كاملة"}
        visible={!!message}
        onHide={onHide}
        style={{ width: "30rem" }}
      >
        <p>{message}</p>
      </Dialog>
    );
  };
  const openNew = () => {
    setIsAddingBrand(true);
    setProduct(emptyProduct);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  function onPageChange(event) {
    getReviews(event.page + 1);
  }

  const onSave = async product => {
    try {
      const response = await axios.post(`${BASE_URL}/dashboard/stores/update`, product, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("userToken")
        }
      });
      const data = response.data;
      // handle response data
      getReviews();
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Success", detail: "Store updated successfully", life: 3000 });
    } catch (error) {
      // handle error
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update Store", life: 3000 });
    }
  };
  const navigate = useNavigate();

  const viewUserDetails = product => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/view-user/${product.user.id}`);
  };
  const viewProductDetails = product => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/edit-product/${product.product.id}`);
  };

  //delete one product
  const hideDeleteBrandDialoge = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = product => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const url = `${BASE_URL}/dashboard/complaint-messages/delete/${product.id}`;

      await axios.delete(url, {
        headers: {
          'Authorization': `Bearer ${token}`
         }
      });
  
      const updatedProducts = products.filter(val => val.id !== product.id);
      setProducts(updatedProducts);
  
      setProduct(emptyProduct);
  
      setDeleteProductDialog(false);
  
      toast.current.show({ severity: "success", summary: "Successful", detail: "Comment Deleted", life: 3000 });
    } catch (error) {
      setDeleteProductDialog(false);
  
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete Comment", life: 4000 });
    }
  };
  

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  //delete selected product

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    try {
      const payload = {
        review_id: product.id
      };
      // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
      const deletePromises = selectedProducts.map(product => {
        return axios.delete(`${BASE_URL}/dashboard/reviews/delete`, {
          headers: {
            token: localStorage.getItem("userToken")
          },
          data: payload
        });
      });
      await Promise.all(deletePromises);

      // Update the products list state to remove the deleted products
      const updatedProducts = products.filter(val => !selectedProducts.includes(val));
      setProducts(updatedProducts);

      // Reset the selected products state and hide the delete products dialog
      setSelectedProducts(null);
      setDeleteProductsDialog(false);

      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Successful", detail: "Brands Deleted", life: 3000 });
    } catch (error) {
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete brands", life: 3000 });
    }
  };

  const actionBodyTemplate = rowData => {
    return (
      <React.Fragment>
        <div className="d-flex gap-4">
          <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
        </div>
      </React.Fragment>
    );
  };

  const saveBrand = async brand => {
    try {
      const response = await fetch(`${BASE_URL}/dashboard/stores/create`, {
        method: "POST",
        headers: {
          token: localStorage.getItem("userToken"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(brand)
      });
      const data = await response.json();
      // handle response data
      getReviews();
      setProducts([...products, data]); // add new store to products state
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Success", detail: "Store added successfully", life: 3000 });
    } catch (error) {
      // handle error
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add Store", life: 3000 });
    }
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const handleSaveClick = () => {
    if (isAddingBrand) {
      saveBrand(product);
    } else {
      onSave(product);
    }
    setIsAddingBrand(false);
    setProduct(emptyProduct);
    setProductDialog(false);
  };

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );

  const messagePopup = rowData => {
    const message = rowData?.content;
    const chunks = message?.match(/.{1,10}/g); // Split into chunks of 10 characters
    const firstChunk = chunks?.[0];

    return (
      <div className="d-flex gap-2 text-center">
        <span>{firstChunk}</span>

        {message !== null && <i className="text-primary pi pi-eye" onClick={() => setPopupMessage(message)} />}

        <PopupMessage dir={isLang == "en" ? "ltr" : "rtl"} message={popupMessage} onHide={() => setPopupMessage(null)} />
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <h3 className="m-4">{isLang === "en" ? "Reviews" : "التعليقات"}</h3>
      <div className="card p-2 m-4 rounded-4">
        <DataTable
          stripedRows
          value={products}
          selection={selectedProducts}
          onSelectionChange={e => setSelectedProducts(e.value)}
          dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
        >
          {/* <Column selectionMode="multiple" className="px-3" exportable={true}></Column> */}
          <Column className="text-center" field="id" header={isLang === "en" ? "ID" : "الرقم"} />
          <Column
            className="text-center"
            field="users.name"
            header={isLang === "en" ? "User Name" : "اسم العميل"}
            body={rowData => (
              <Link to={`/view-user/${rowData?.user?.id}`} className="link">
                {rowData?.user?.name}
              </Link>
            )}
          />
          <Column
            className="text-center"
            field="product.name"
            header={isLang === "en" ? "Product" : " المنتج"}
            body={rowData => (
              <Link to={`/edit-product/${rowData?.product?.id}`} className="link">
                {rowData?.product?.name}
              </Link>
            )}
          />
          <Column header="Message" className="text-center" field="content" body={messagePopup} />

          <Column className="text-center" body={actionBodyTemplate} />
        </DataTable>
        <Paginator ref={dtt.current} totalRecords={totalRecords} rows={10} onPageChange={onPageChange} />
      </div>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content ">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              {isLang === "en" ? "Are you sure you want to delete" : " هل أنت متأكد أنك تريد حذف"} <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteBrandDialoge}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              {" "}
              {isLang === "en"
                ? "Are you sure you want to delete the selected products?"
                : "   هل أنت متأكد أنك تريد حذف المنتجات المحددة؟"}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default Reviews;
