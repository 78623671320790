import React, { useState, useEffect, useContext, useRef } from "react";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../context/Enviroment";
import { Button } from "primereact/button";

const Points = () => {
  const [productCount, setProductCount] = useState("");
  const [pointsValue, setPointsValue] = useState("");
  let { isLang, setIsLang } = useContext(VendersContext);
  const toast = useRef(null);

  useEffect(() => {
    fetchPointsValue();
  }, []);

  const fetchPointsValue = async () => {
    try {
      const token = localStorage.getItem("userToken");

      const response = await fetch(`${BASE_URL}/dashboard/settings/user-points`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      const data = await response.json();

      if (response.ok) {
        setPointsValue(data.data);
      } else {
        ////console.log('Failed to fetch points value.');
      }
    } catch (error) {
      console.error("Error fetching points value:", error);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    if (name === "productCount") {
      setProductCount(value);
    } else if (name === "pointsValue") {
      setPointsValue(value);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${BASE_URL}/dashboard/settings/user-points/update`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ user_points: parseInt(pointsValue) })
      });

      if (response.ok) {
        ////console.log('Points value updated successfully!');
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Points value updated successfully!",
          life: 3000
        });
      } else {
        ////console.log('Failed to update points value.');
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update points value", life: 3000 });
      }
    } catch (error) {
      console.error("Error updating points value:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Toast ref={toast} />

      <h5 className="pt-2 d-flex justify-content-center">
        {" "}
        {isLang === "en"
          ? "This value represents the worth of a single approved product."
          : "هذه القيمة تمثل عدد النقاط لمنتج واحد مقبول."}
      </h5>

      <div>
        <label htmlFor="pointsValue" className="h6 mt-3">
          {" "}
          {isLang === "en" ? "Points Value:" : "قيمة النقاط"}
        </label>
        <input
          className="form-control form-control-lg mb-3"
          type="number"
          id="pointsValue"
          name="pointsValue"
          value={pointsValue}
          onChange={handleInputChange}
        />
      </div>
      <div className="w-1000 justify-content-center text-center mt-4 d-flex ">
        <Button type="submit" severity="success" className="rounded-3 w-50 justify-content-center">
          {isLang === "en" ? "Save" : "حفظ"}
        </Button>
      </div>
    </form>
  );
};

export default Points;
