import React, { useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown"; // Import from PrimeReact
import Navbar from "react-bootstrap/Navbar";
import { HiMenuAlt4 } from "react-icons/hi";
import { Link } from "react-router-dom";
import Icons from "../../constants/Icons";
import { VendersContext } from "./../../context/Store";
import "./Navber.scss";
import { useTranslation } from "react-i18next";
import { FlagIcon } from "react-flag-kit";

function Navber() {
  const { t, i18n } = useTranslation();
  let { LogOut, isOpen, setIsOpen, isLang, setIsLang } = useContext(VendersContext);
  useEffect(() => {
    setIsLang("en");
    i18n.changeLanguage("en");
  }, []);
  const languageOptions = [{ value: "ar", label: "العربية", flag: "SA" }, { value: "en", label: "English", flag: "US" }];

  const handleLanguageChange = language => {
    i18n.changeLanguage(language);
    setIsLang(language);
  };

  const renderOption = option => (
    <div className="p-d-flex p-ai-center">
      <FlagIcon code={option.flag} size={24} />
      <span className="p-ml-2">{option.label}</span>
    </div>
  );
  const getUserAvatar = () => {
    const userPhoto = localStorage.getItem("userPhoto");
    if (userPhoto) {
      return <img src={userPhoto} alt="User Avatar" className="avatar-image ml-2" width="53px" />;
    }
    return null;
  };
  return (
    <Navbar className="navSubMain px-4 ">
      <div className={`app__navbar-menu ${isLang === "ar" ? "ms-2 " : "me-2"}`}>
        <HiMenuAlt4 onClick={() => setIsOpen(!isOpen)} />
      </div>

      <span className="chang__lang lang-dropdown">
        <Dropdown
          value={isLang}
          options={languageOptions}
          onChange={e => handleLanguageChange(e.value)}
          optionLabel={renderOption}
          placeholder={
            <>
              <Icons.Language size={18} /> {isLang === "ar" ? "تغييـر اللغـة" : "Change Lang"}
            </>
          }
          className={` d-flex gap-2 align-items-center `}
        />
      </span>

      <div className="dropdown" id="basic-nav-dropdown2">
        <div role="button" data-bs-toggle="dropdown" aria-expanded="false" className="d-flex gap-2 flex-row">
          <span> {getUserAvatar()}</span>
          <div className="d-flex flex-row justify-content-center align-items-center gap-1 mr-2">
            <span> {localStorage.getItem("UserName")}</span>
            <i className="pi pi-angle-down" />
          </div>
        </div>
        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ left: isLang === "ar" ? "0" : "-120px", zIndex: 99999, top: "47px" }}
        >
          <li className="dropdown-item">
            <Link to={"/admin/login"} onClick={LogOut} className="dropdown-item">
              <div style={{ display: "flex", alignItems: "center" }}>
                <img className={isLang === "ar" ? "ms-2" : "me-2"} src={Icons.logout} alt="logout" width={18} height={18} />
                <span>{t("logout")}</span>
              </div>
            </Link>
          </li>
          <li className="dropdown-item">
              <Link to={"/profile"} className="dropdown-item">
                <div className="d-flex align-items-center gap-2">
                  <i className="pi pi-user" />
                  <span>{t("profile")}</span>
                </div>
              </Link>
            </li>
        </ul>
      </div>

      <Navbar.Toggle />
    </Navbar>
  );
}

export default Navber;
