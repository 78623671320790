import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../context/Enviroment";
import CustomPagination from "../../Components/Paginator/Paginator";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Loading from "../../Components/Loading/Loading";

const RoleTable = () => {
  let { isLang, setIsLang } = useContext(VendersContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [roles, setRoles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]); // Initialize as an empty array
  const [newRoleName, setNewRoleName] = useState("");
  const [newNameAR, setNewNameAR] = useState("");
  const [newNameDE, setNewNameDE] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editedRole, setEditedRole] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editedName, setEditedName] = useState("");
  const toast = useRef(null);

  useEffect(
    () => {
      fetchRoles();
      fetchPermissions();
    },
    [selectedLanguage]
  );

  const fetchRoles = async () => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${BASE_URL}/dashboard/roles?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: selectedLanguage
        }
      });
      const { roles: fetchedRoles, total } = response.data.data;
      setRoles(response.data.data.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (roleId, roleName, permissions, name_de, name_ar) => {
    setSelectedRole(roleId);
    setNewRoleName(roleName);
    console.log(permissions);
    const permissionNames = permissions.map(permission => permission.name);
    setSelectedPermissions(permissionNames);
    setNewNameDE(name_de || "");
    setNewNameAR(name_ar || "");
  };

  const handleCancelEdit = () => {
    setSelectedRole(null);
    setNewRoleName("");
    setNewNameDE("");
    setNewNameAR("");
  };

  const handleSaveEdit = async () => {
    try {
      const payload = {
        name: newRoleName,
        permissions: selectedPermissions
      };

      const token = localStorage.getItem("userToken");
      const response = await axios.post(`${BASE_URL}/dashboard/roles/update/${selectedRole}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: selectedLanguage
        }
      });

      fetchRoles();
      toast.current.show({ severity: "success", summary: "Success", detail: "Role updated successfully", life: 3000 });

      // Reset state
      setSelectedRole(null);
      setNewRoleName("");
      setSelectedPermissions([]);
      setNewNameDE("");
      setNewNameAR("");
    } catch (error) {
      console.error("Error updating role:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update role", life: 3000 });
    }
  };

  async function searchRoles(e, page) {
    if (e.target.value) {
      try {
        const token = localStorage.getItem("userToken");
        const lang = "en";

        const response = await axios.get(`${BASE_URL}/dashboard/roles?page=${page}&search=${e.target.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: lang
          }
        });

        const { data } = response;
        setRoles(data?.data?.data);
      } catch (error) {
        console.error("Error searching admins:", error);
      }
    } else {
      fetchRoles();
    }
  }
  const handleDelete = async roleId => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.isConfirmed) {
        deleteRole(roleId);
      }
    });
  };

  const deleteRole = async roleId => {
    try {
      const token = localStorage.getItem("userToken");
      await axios.delete(`${BASE_URL}/dashboard/roles/delete/${roleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });
      toast.current.show({ severity: "success", summary: "Success", detail: "Deleted Successfully", life: 3000 });
      fetchRoles();
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete this role", life: 3000 });
    }
  };

  const getLocalizedRoleName = role => {
    if (role.translations && role.translations.length > 0) {
      const translation = role.translations.find(translation => translation.locale === selectedLanguage);
      if (translation && translation.name) {
        return translation.name;
      }
    }
    return role.name;
  };

  const showDialog = () => {
    setNewRoleName("");
    setSelectedPermissions([]);
    setSelectedRole(null);
    setDisplayDialog(true);
  };

  const hideDialog = () => {
    setDisplayDialog(false);
  };
  const fetchPermissions = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${BASE_URL}/dashboard/roles/permissions`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const permissionsData = response.data.data;
      const permissionsArr = permissionsData.map(permission => ({
        id: permission.id,
        name: permission.name
      }));
      setPermissions(permissionsArr);
      //console.log(permissionsArr);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handlePermissionChange = event => {
    const permissionId = event.target.value;
    const isChecked = event.target.checked;
    const permissionName = permissions.find(permission => permission.id.toString() === permissionId)?.name;

    setSelectedPermissions(prevPermissions => {
      const safePrevPermissions = Array.isArray(prevPermissions) ? prevPermissions : [];
      if (isChecked) {
        return safePrevPermissions.includes(permissionName) ? safePrevPermissions : [...safePrevPermissions, permissionName];
      } else {
        return safePrevPermissions.filter(name => name !== permissionName);
      }
    });
  };

  const createRole = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.post(
        `${BASE_URL}/dashboard/roles/create`,
        {
          name: newNameAR,
          permissions: selectedPermissions
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const newRole = response.data.data;
      setRoles([...roles, newRole]);
      toast.current.show({ severity: "success", summary: "Success", detail: "Created Successfully", life: 3000 });

      fetchRoles();
      hideDialog();
    } catch (error) {
      console.error("Error creating role:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add this role", life: 3000 });
    }
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = roles.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(roles.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  return (
    <>
      <Toast ref={toast} />
      {isLoading ? <Loading /> : null}
      <div
        dir={isLang == "en" ? "ltr" : "rtl"}
        className="flex mb-3 d-flex px-2  flex-wrap gap-2 align-items-center justify-content-between"
      >
        <Button
          label={isLang === "en" ? "Add Role" : "إضافة وظيفة"}
          icon="pi pi-plus"
          severity="success"
          className="rounded-3"
          onClick={showDialog}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            onChange={searchRoles}
            type="search"
            onInput={e => searchRoles(e?.target?.value)}
            placeholder={isLang === "en" ? "Search..." : "البحث"}
          />
        </span>
      </div>
      <div dir={isLang == "en" ? "ltr" : "rtl"}>
        <table className="table pt-2">
          <thead className="table-light">
            <tr>
              <th scope="col">{isLang === "en" ? "Role ID" : "الرقم"}</th>
              <th scope="col">{isLang === "en" ? "Role Name" : "اسم الوظيفة"}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody className="text-center">
            {currentData.map(role => (
              <tr className="role-tr" key={role.id}>
                <td>{role.id}</td>
                <td>{getLocalizedRoleName(role)}</td>
                <td className="d-flex gap-2">
                  <Button
                    icon="pi pi-pencil"
                    rounded
                    outlined
                    className="mr-2"
                    onClick={() => handleEdit(role.id, role.name, role.permissions)}
                  />
                  <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(role.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          indexOfLastRow={indexOfLastRow}
          reportData={roles}
        />
      </div>
      {/* Dialog for creating a new role */}
      <Dialog
        visible={displayDialog}
        onHide={hideDialog}
        header={isLang === "en" ? "Add Role" : "إضافة وظيفة"}
        style={{ width: "45rem", height: "400px" }}
        dir={isLang === "ar" ? "rtl" : "ltr"}
      >
        <div className="d-flex gap-3">
          <label htmlFor="newNameAR" className="font-bold h5 d-flex align-items-center">
            {isLang === "en" ? "AR_Name:" : "الاسم:"}
          </label>
          <InputText id="newNameAR" value={newNameAR} onChange={e => setNewNameAR(e.target.value)} required className="w-100" />
        </div>

        <div className="form-check mt-4">
          <h5 className="secondColor text-center">
            {isLang === "en" ? "Select This Role Permissions" : "حدد صلاحيات الوظيفة"}
          </h5>
          {permissions.map(permission => (
            <div dir="ltr" className="mb-2" key={permission.id}>
              <input
                type="checkbox"
                className="form-check-input checkbox-design"
                value={permission.id}
                // checked={selectedPermissions.includes(permission.id)}
                onChange={handlePermissionChange}
                disabled={false}
              />
              <label className="form-check-label h6 mx-2" htmlFor={permission.id}>
                {permission.name} {/* Display permission name here */}
              </label>
            </div>
          ))}
        </div>
        <div className="text-center p-dialog-footer">
          <Button
            label={isLang === "en" ? "Save" : " حفظ"}
            icon="pi pi-check"
            severity="success"
            className="rounded-3 p-button"
            onClick={createRole}
          />

          <Button
            label={isLang === "en" ? "Cancel" : "إلغاء"}
            icon="pi pi-times"
            severity="danger"
            className="rounded-3 p-button-secondary"
            onClick={hideDialog}
          />
        </div>
      </Dialog>
      <Dialog
        dir={isLang === "en" ? "ltr" : "rtl"}
        visible={selectedRole !== null}
        onHide={handleCancelEdit}
        header={isLang === "en" ? "Edit Role" : "تعديل وظيفة"}
        style={{ width: "45rem", height: "400px" }}
        footer={
          <div className="text-center p-dialog-footer">
            <Button
              label={isLang === "en" ? "Save" : "حفظ"}
              icon="pi pi-check"
              severity="success"
              className="rounded-3 p-button"
              onClick={handleSaveEdit}
            />
            <Button
              label={isLang === "en" ? "Cancel" : "إلغاء"}
              icon="pi pi-times"
              severity="danger"
              className="rounded-3 p-button-secondary"
              onClick={handleCancelEdit}
            />
          </div>
        }
      >
        <div className="edit-content">
          <div className="d-flex gap-3">
            <label htmlFor="newRoleNameEN" className="font-bold h5 d-flex w-25 align-items-center">
              {t("roleName")}
            </label>
            <InputText
              id="newRoleNameEN"
              value={newRoleName}
              onChange={e => setNewRoleName(e.target.value)}
              required
              className="w-100"
            />
          </div>

          <div className="form-check mt-4">
            <h5 className="secondColor text-center">
              {isLang === "en" ? "Select This Role Permissions" : "تعديل صلاحيات الوظيفة"}
            </h5>
            {permissions &&
              permissions.map(permission => (
                <div dir="ltr" className="mb-2" key={permission?.id}>
                  <input
                    type="checkbox"
                    className="form-check-input checkbox-design"
                    id={`permission-${permission.id}`}
                    value={permission.id}
                    checked={selectedPermissions ? selectedPermissions?.includes(permission?.name) : false}
                    onChange={handlePermissionChange}
                    disabled={false}
                  />

                  <label htmlFor={`permission-${permission?.id}`}>{permission?.name}</label>
                </div>
              ))}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RoleTable;
