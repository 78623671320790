import React, { useState, useEffect, useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import { MultiSelect } from "primereact/multiselect";
import halalStatus from "../../assets/Images/halalStatus.png";
import haramStatus from "../../assets/Images/haramStatus.png";
import MushboohStatus from "../../assets/Images/MushboohStatus.png";
import { format, parseISO } from "date-fns";

import { Image } from "primereact/image";
import { VendersContext } from "../../context/Store";
import { BASE_URL } from "../../context/Enviroment";
import Loading from "../../Components/Loading/Loading";

const Products = props => {
  let { isLang } = useContext(VendersContext);
  const [isLoading, setIsLoading] = useState(false);

  const [products, setProducts] = useState([]);
  // const [insectsData, setInsectsData] = useState([]);
  const [selectedInsects, setSelectedInsects] = useState([]);

  const perPage = 10;
  const [page, setPage] = useState(1);


  async function getProducts(page) {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("userToken");
      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get(`${BASE_URL}/dashboard/products?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.data);
      //console.log(data.data.data)
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  


  const [insectsData, setinsectsData] = useState([]);



  async function searchProducts(e, page) {
    const token = localStorage.getItem("userToken");

    if (e.target.value) {
      let { data } = await axios.get(`${BASE_URL}/dashboard/products`, {
        params: {
          sort_by: 'name',
          page: page,
          search: e.target.value, 
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      setProducts(data.data.data.data);
    } else {
      getProducts();
    }
  }

  const [currentpage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  let cancelTokenSource = axios.CancelToken.source();

  const onPageChange = event => {
    const newPage = event.page + 1; 
    setCurrentPage(newPage);
    getProducts(newPage);
    setFirst(event.first);
    setRows(event.rows);
  };

  function onSelectionChange(event) {
    setSelectedProducts(event.value);
  }

  useEffect(
    () => {
    getProducts()
    },
    [page, selectedInsects]
  );

  let emptyProduct = {
    id: null,
    name: ""
  };

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const toast = useRef(null);
  const dt = useRef(null);
  const dtt = useRef(null);


  const createdAtBodyTemplate = rowData => {
    return format(parseISO(rowData.created_at), "dd/MM/yyyy");
  };
  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = () => {
    setSubmitted(true);

    if (product.name.trim()) {
      let _products = [...products];
      let _product = { ...product };

      if (product.id) {
        const index = findIndexById(product.id);

        _products[index] = _product;
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Updated", life: 3000 });
      } else {
        _product.id = createId();
        _product.image = "product-placeholder.svg";
        _products.push(_product);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Created", life: 3000 });
      }

      setProducts(_products);
      setProductDialog(false);
      setProduct(emptyProduct);
    }
  };
  const navigate = useNavigate();
  const location = useLocation();

  const editProduct = product => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/edit-product/${product.id}?page=${currentpage}`);
    ////console.log(currentpage);
  };

  const confirmDeleteProduct = product => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      const token = localStorage.getItem("userToken");
  
      await axios.delete(`${BASE_URL}/dashboard/products/delete/${product.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
  
      const updatedProducts = products.filter(val => val.id !== product.id);
      setProducts(updatedProducts);
  
      setProduct(emptyProduct);
      setDeleteProductDialog(false);
  
      toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete product", life: 3000 });
    }
  };
  
  const findIndexById = id => {
    let index = -1;

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };

  const createId = () => {
    let id = "";
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return id;
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };
  const deleteSelectedProducts = async () => {
    try {
      const token = localStorage.getItem("userToken");
      
      const selectedProductIds = selectedProducts.map(product => product.id);
  
      const requestData = {
        product_ids: selectedProductIds
      };
  
      await axios.delete(`${BASE_URL}/dashboard/products/delete-selected`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: requestData
      });
  
      const updatedProducts = products.filter(val => !selectedProductIds.includes(val.id));
      setProducts(updatedProducts);
  
      setSelectedProducts(null);
      setDeleteProductsDialog(false);
  
      toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    } catch (error) {
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete products", life: 3000 });
    }
  };

 
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);
  };


  const imageBodyTemplate = rowData => {
    const frontImage = rowData.images && rowData.images.find(img => img.type === "front");

    return frontImage ? (
      <Image src={frontImage.image} alt="Front" width="80px" height="70px" preview />

    ) : (
      <span>No Image</span>
    );
  };


  const statusBodyTemplate = rowData => {
    let colorClass, label, imageSource;

    switch (rowData?.status) {
      case "MUSHBOOH": // Mushbooh
        colorClass = "text-warning";
        label = "Mushbooh";
        imageSource = MushboohStatus;
        break;
      case "HALAL": // Halal
        colorClass = "rounded-5 text-success";
        label = "Halal";
        imageSource = halalStatus;
        break;
      case "HARAM": // Haram
        colorClass = "text-danger rounded-5";
        label = "Haram";
        imageSource = haramStatus;
        break;
      default:
        label = "Unknown";
        colorClass = "text-muted";
        break;
    }

    return (
      <div className="d-flex gap-2">
        <label className={`${colorClass} status-btn h6 mt-2`}>{label}</label>
        {imageSource && <img src={imageSource} alt={label} style={{ height: "20px" }} />} {/* Adjust image size as needed */}
      </div>
    );
  };

  const nameBodyTemplate = rowData => {
    return rowData.name ? rowData.name : "No Name";
  };

  const actionBodyTemplate = rowData => {
    return (
      <React.Fragment>
        <div className="d-flex gap-4">
          <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
          <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />{" "}
        </div>
      </React.Fragment>
    );
  };

  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className="d-flex align-items-center ">
        <h3 className="p-2 ">{isLang == "en" ? "Total Products:" : " إجمالي المنتجات:"}</h3>
        <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h3" />
      </div>
    </div>
  );
  const leftToolbarTemplate = () => {
    return (
      <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
        <div className=" d-flex flex-wrap gap-3">
          <Link to="/addProduct">
            <Button
              label={isLang === "en" ? "Add Product" : "إضافة منتج"}
              icon="pi pi-plus"
              severity="success"
              className="rounded-3"
            />
          </Link>
          <Button
            label={isLang === "en" ? "Delete" : "حذف"}
            icon="pi pi-trash"
            severity="danger"
            className="rounded-3"
            onClick={() => confirmDeleteSelected(selectedProducts)}
            disabled={!selectedProducts || !selectedProducts.length}
          />
        </div>
      </div>
    );
  };

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label={isLang === "en" ? "Cancel" : "إلغاء "}
        icon="pi pi-times"
        className="p-button-secondary"
        outlined
        onClick={hideDialog}
      />
      <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label={isLang === "en" ? "No" : " الغاء"}
        icon="pi pi-times"
        className="p-button-secondary"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label={isLang === "en" ? "No" : " الغاء"}
        icon="pi pi-times"
        className="p-button-secondary"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );


  const insectsOptions = insectsData.map(insect => ({
    label: insect.name,
    value: insect.id
  }));
  return (
    <div>
            <h3 className="mx-4 mt-2">{isLang === "en" ? "Manage Products" : "إدارة المنتجات"}</h3>

      <Toast ref={toast} />
      <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between px-3">
        <Toolbar className="mb-4" left={leftToolbarTemplate} />
        <div className="p-d-flex p-ai-center d-flex flex-row gap-4 mx-3">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              onChange={searchProducts}
              type="search"
              onInput={e => searchProducts(e.target.value)}
              placeholder={isLang == "en" ? "Search..." : "البحث"}
            />
          </span>
        
        </div>
      </div>

      <div className="card mx-4 p-2 rounded-4 ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <span className="bg-danger">{insectsData?.name}</span>

            <DataTable
              stripedRows
              ref={dt}
              value={products}
              selection={selectedProducts}
              onSelectionChange={onSelectionChange}
              dataKey="id"
              globalFilter={globalFilter}
              header={header}
              
            >
              <Column selectionMode="multiple" width="20px" className="px-3" exportable={true} />
              <Column field="barcode" header={isLang === "en" ? "Barcode" : "الباركود"} style={{ minWidth: "12rem" }} />
              <Column
                field="name"
                header={isLang === "en" ? "Name" : "المنتج"}
                body={nameBodyTemplate}
                className="px-3"
                style={{ minWidth: "16rem" }}
              />
              <Column field="status" body={statusBodyTemplate} header={isLang === "en" ? "Status" : "الحالة"} />
              <Column field="image" header={isLang === "en" ? "Image" : "الصورة"} body={imageBodyTemplate} />

              <Column
                field="created_at"
                header={isLang === "en" ? "Created At" : "تاريخ الإنشاء"}
                body={createdAtBodyTemplate}
                style={{ minWidth: "12rem" }}
              />
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "12rem" }} />
            </DataTable>

            <Paginator
              ref={dtt.current}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
              currentpage={currentpage - 1}
            />
          </>
        )}
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: "34rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Product Details"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        {product.image && (
          <img
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.image}
            className="product-image block m-auto pb-3"
          />
        )}
        <div className="field">
          <label htmlFor="name" className="font-bold">
            Name
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={e => onInputChange(e, "name")}
            required
            autoFocus
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && <small className="p-error">Name is required.</small>}
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            Description
          </label>
          <InputTextarea
            id="description"
            value={product.description}
            onChange={e => onInputChange(e, "description")}
            required
            rows={3}
            cols={20}
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              Are you sure you want to delete <b>{product.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && <span>Are you sure you want to delete the selected products?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default Products;
