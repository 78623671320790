import React, { useState, useEffect, useRef, useContext } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import { VendersContext } from "../../context/Store";
import { BASE_URL } from "../../context/Enviroment";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading/Loading";

const Stores = props => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  let { isLang, setIsLang } = useContext(VendersContext);

  const [products, setProducts] = useState([]);

  async function getStores(page) {
    setIsLoading(true);

    try {
      const headers = {
        token: localStorage.getItem("userToken")
      };

      const response = await axios.get(`${BASE_URL}/dashboard/stores?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.stores);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function searchStores(e, page) {
    if (e.target.value) {
      let { data } = await axios.get(`${BASE_URL}/dashboard/tables_search/stores/${e.target.value}?page=${page}`, {
        headers: {
          token: localStorage.getItem("userToken")
        }
      });
      setProducts(data.data.stores);
      ////console.log(data);
    } else {
      getStores();
    }
  }

  useEffect(() => {
    getStores(1);
  }, []);

  let emptyProduct = {
    name: ""
  };

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [nameTouched, setNameTouched] = useState(false);
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);
  const [isAddingBrand, setIsAddingBrand] = useState(false);

  const openNew = () => {
    setIsAddingBrand(true);
    setProduct(emptyProduct);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const onPageChange = event => {
    const newPage = event.page + 1; // Assuming the API pagination starts from 1
    setCurrentPage(newPage);
    getStores(newPage);
    setFirst(event.first);
    setRows(event.rows);
  };

  const onSave = async product => {
    try {
      const response = await axios.post(`${BASE_URL}/dashboard/stores/update`, product, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("userToken")
        }
      });
      const data = response.data;
      // handle response data
      getStores(currentPage);
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Success", detail: "Store updated successfully", life: 3000 });
    } catch (error) {
      // handle error
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update Store", life: 3000 });
    }
  };
  const editProduct = product => {
    setProduct({ id: product.id, name: product.name });
    setIsAddingBrand(false);
    setProductDialog(true);
  };

  //delete one product
  const hideDeleteBrandDialoge = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = product => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    try {
      // Send a DELETE request to the API endpoint with the product ID to be deleted and the Authorization header with the token value
      await axios.delete(`${BASE_URL}/dashboard/stores/delete/${product.id}`, {
        headers: {
          token: localStorage.getItem("userToken")
        }
      });

      // Update the products list state to remove the product that was deleted
      const updatedProducts = products.filter(val => val.id !== product.id);
      setProducts(updatedProducts);

      // Reset the product state
      setProduct(emptyProduct);

      // Hide the delete product dialog
      setDeleteProductDialog(false);

      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Successful", detail: "Brand Deleted", life: 3000 });
    } catch (error) {
      ////console.log(error.response);
      // Show an error toast message
      setDeleteProductDialog(false);

      toast.current.show({ severity: "error", summary: "Error", detail: "This store is related to some products", life: 4000 });
    }
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  //delete selected product

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = async () => {
    try {
      // Send a DELETE request to the API endpoint for each selected product with the Authorization header with the token value
      const deletePromises = selectedProducts.map(product => {
        return axios.delete(`${BASE_URL}/dashboard/stores/delete/${product.id}`, {
          headers: {
            token: localStorage.getItem("userToken")
          }
        });
      });
      await Promise.all(deletePromises);

      // Update the products list state to remove the deleted products
      const updatedProducts = products.filter(val => !selectedProducts.includes(val));
      setProducts(updatedProducts);

      // Reset the selected products state and hide the delete products dialog
      setSelectedProducts(null);
      setDeleteProductsDialog(false);

      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Successful", detail: "Brands Deleted", life: 3000 });
    } catch (error) {
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete brands", life: 3000 });
    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };

    _product[`${name}`] = val;

    setProduct(_product);

    if (name === "name") {
      setNameTouched(true);
    }
  };

  const actionBodyTemplate = rowData => {
    return (
      <div className="d-flex gap-4">
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editProduct(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteProduct(rowData)} />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex flex-wrap gap-3">
        <Button
          label={isLang === "en" ? "Add Store" : "إضافة متجر"}
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
          className="rounded-3"
        />
        <Button
          label={isLang === "en" ? "Delete" : "حذف"}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          className={selectedProducts && selectedProducts.length ? "rounded-3" : "p-disabled rounded-3"}
        />
      </div>
    );
  };

  const saveBrand = async brand => {
    try {
      const response = await fetch(`${BASE_URL}/dashboard/stores/create`, {
        method: "POST",
        headers: {
          token: localStorage.getItem("userToken"),
          "Content-Type": "application/json"
        },
        body: JSON.stringify(brand)
      });
      const data = await response.json();
      // handle response data
      getStores();
      setProducts([...products, data]); // add new store to products state
      setProductDialog(false); // close dialog
      // Show a success toast message
      toast.current.show({ severity: "success", summary: "Success", detail: "Store added successfully", life: 3000 });
    } catch (error) {
      // handle error
      ////console.log(error);
      // Show an error toast message
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add Store", life: 3000 });
    }
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const handleSaveClick = () => {
    if (isAddingBrand) {
      saveBrand(product);
    } else {
      onSave(product);
    }
    setIsAddingBrand(false);
    setProduct(emptyProduct);
    setProductDialog(false);
  };
  const productDialogFooter = (
    <div>
      <Button label={isLang === "en" ? "Save" : " حفظ"} icon="pi pi-check" onClick={handleSaveClick} />
      <Button
        label={isLang === "en" ? "Cancel" : "إلغاء "}
        icon="pi pi-times"
        onClick={hideDialog}
        className="p-button-secondary"
      />
    </div>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" className="p-button-secondary" outlined onClick={hideDeleteProductDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label={isLang === "en" ? "No" : " الغاء"} icon="pi pi-times" className="p-button-secondary" outlined onClick={hideDeleteProductsDialog} />
      <Button label={isLang === "en" ? "Yes" : " نعم"} icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />
      <h3 className="mx-4 mt-2">{isLang === "en" ? "Manage Stores" : "إدارة المتاجر"}</h3>
      <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between mx-2">
        <Toolbar className="mb-4" left={leftToolbarTemplate} />

        <span className="p-input-icon-left mx-4">
          <i className="pi pi-search" />
          <InputText
            onChange={searchStores}
            type="search"
            onInput={e => searchStores(e.target.value)}
            placeholder={isLang == "en" ? "Search..." : "البحث"}
          />
        </span>
      </div>

      <div className="card mx-4 p-2 rounded-4 ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <DataTable
              stripedRows
              dir={isLang == "en" ? "ltr" : "rtl"}
              value={products}
              selection={selectedProducts}
              onSelectionChange={e => setSelectedProducts(e.value)}
              dataKey="id"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              globalFilter={globalFilter}
            >
              <Column selectionMode="multiple" className="px-3" exportable={true} />
              <Column field="id" header={isLang === "en" ? "ID" : "الرقم"} style={{ minWidth: "12rem" }} />
              <Column field="name" header={isLang === "en" ? "Brand Name" : "المتجر"} style={{ minWidth: "16rem" }} />
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "12rem" }} />
            </DataTable>
            <Paginator
              ref={dtt.current}
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
              currentPage={currentPage - 1}
            />
          </>
        )}
      </div>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={productDialog}
        style={{ width: "34rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("addStore")}
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold h6">
            {t("name")}
          </label>
          <InputText
            id="name"
            value={product.name}
            onChange={e => onInputChange(e, "name")}
            required
            className={classNames({ "p-invalid": submitted && !product.name })}
          />
          {submitted && !product.name && <small className="p-error">Name is required.</small>}
        </div>
      </Dialog>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content ">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && (
            <span>
              {t("deleteConfirmation")} <b>{product.name}</b> ?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteBrandDialoge}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {product && <span>{t("selectedProductsConfirmation")} ?</span>}
        </div>
      </Dialog>
    </div>
  );
};

export default Stores;
