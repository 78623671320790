import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Pixels() {
  const { t } = useTranslation();

  return (
    <div>
      <label htmlFor="faq_text_en" className="h6 mt-3">
        Google Analytics:{" "}
      </label>
      <textarea rows="3" className="form-control form-control-lg" id="faq_text_en" name="faq_text_en" />

      <label htmlFor="faq_text_de" className="h6 mt-3">
        Facebook Pixel
      </label>
      <textarea rows="3" className="form-control form-control-lg" id="faq_text_de" name="faq_text_de" />

      <label htmlFor="faq_text_ar" className="h6 mt-3">
        Ads
      </label>
      <textarea rows="3" className="form-control form-control-lg" id="faq_text_ar" name="faq_text_ar" />
      <div className="w-100 justify-content-center text-center mt-4 d-flex ">
        <Button type="submit" severity="success" className="rounded-3 w-50 justify-content-center">
          {t("save")}
        </Button>
      </div>
    </div>
  );
}
