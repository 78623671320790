import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { VendersContext } from "../../context/Store";
import { BASE_URL } from "../../context/Enviroment";
import { format, parseISO } from "date-fns";
import halalStatus from "../../assets/Images/halalStatus.png";
import haramStatus from "../../assets/Images/haramStatus.png";
import MushboohStatus from "../../assets/Images/MushboohStatus.png";
export default function SuggestedReport() {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [products, setProducts] = useState("");
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterValue, setFilterValue] = useState(null);

  const [sortOrder, setSortOrder] = useState("");

  const dtt = useRef(null);

  const handleFilterChange = event => {
    setFilterValue(event.target.value);
    setFilterValue(event.target.value);
    setFirst(0);
  };

  const filteredProducts = filterValue ? products.filter(product => product.safe === filterValue) : products;
  useEffect(
    () => {
      fetchData(page);
    },
    [sortOrder, page]
  );

  const fetchData = () => {
    let url = `${BASE_URL}/dashboard/reports/approved-suggested-products?page=${page}`;
    const token = localStorage.getItem("userToken");

    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      url = `${BASE_URL}/dashboard/reports/approved-suggested-products?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=$${page}`;
    }

    if (filterValue) {
      url += `&safe=${filterValue}`;
    }
    const sortBy = "points";

    if (sortOrder) {
      url += `&sort_by=${sortBy}&sort_direction=${sortOrder === "1" ? "asc" : "desc"}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setProducts(response?.data?.data.data);
        setTotalRecords(response?.data.data.total);
      })
      .catch(error => {
        ////console.log(error);
      });
  };

  const formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = event => {
    const newPage = event.page + 1; // Assuming the API pagination starts from 1
    setCurrentPage(newPage);
    ////console.log(currentPage);
    fetchData(newPage);
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page + 1);
  };

  const handleSortChange = e => {
    setSortOrder(e.target.value);
    setPage(1);
  };

  const handleSearch = () => {
    fetchData();
  };

  const statusBodyTemplate = rowData => {
    let colorClass, label, imageSource;

    switch (rowData?.status) {
      case "MUSHBOOH": // Mushbooh
        colorClass = "text-warning";
        label = "Mushbooh";
        imageSource = MushboohStatus;
        break;
      case "HALAL": // Halal
        colorClass = "rounded-5 text-success";
        label = "Halal";
        imageSource = halalStatus;
        break;
      case "HARAM": // Haram
        colorClass = "text-danger rounded-5";
        label = "Haram";
        imageSource = haramStatus;
        break;
      default:
        label = "Unknown";
        colorClass = "text-muted";
        break;
    }

    return (
      <div className="d-flex gap-2">
        <label className={`${colorClass} status-btn h6 mt-2`}>{label}</label>
        {imageSource && <img src={imageSource} alt={label} style={{ height: "20px" }} />} {/* Adjust image size as needed */}
      </div>
    );
  };
  return (
    <>
              <h3 className="mx-4 mt-2">{isLang === "en" ? "Suggested Products Report" : "تقرير المنتجات المقترحة"}</h3>

      <div className=" card p-4 mb-3 mx-4 rounded-4 mt-3 d-flex flex-row justify-content-between">
        <div className="d-flex flex-row align-items-center gap-3">
          <h6>{isLang === "en" ? "From:" : "من:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            placeholder={isLang === "en" ? "enter start date" : "تاريخ البداية"}
            value={startDate}
            onChange={e => setStartDate(e.value)}
            showIcon
            iconPos="left"
          />
        </div>
        <div className="d-flex flex-row align-items-center gap-3">
          <h6>{isLang === "en" ? "To:" : "إلى:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            placeholder={isLang === "en" ? "enter end date" : "تاريخ النهاية"}
            value={endDate}
            onChange={e => setEndDate(e.value)}
            showIcon
            iconPos="left"
          />
        </div>
        <Button label={isLang === "en" ? "Search" : "البحث"} onClick={handleSearch} className="rounded-3 search-btn" />
      </div>

      <div className="card p-2 mb-3 mx-4 rounded-4 ">
        <div className="d-flex align-items-center ">
          <h5 className="p-2 ">{isLang === "en" ? "Total Products:" : "إجمالي المنتجات"}</h5>
          <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h5" />
        </div>
        <DataTable stripedRows value={filteredProducts} rows={rows} first={first}>
          <Column
            className="text-center"
            field="name"
            header={isLang === "en" ? "Name" : "المنتج"}
            body={rowData => (
              <Link to={`/edit-product/${rowData.product_id}`}>{rowData.name !== null ? rowData.name : rowData.code}</Link>
            )}
          />
          <Column
            className="text-center"
            field="status"
            body={statusBodyTemplate}
            header={isLang === "en" ? "Status" : "الحالة"}
          />
          <Column className="text-center" field="user_name" header={isLang === "en" ? "User NAme" : "اسم المستخدم"} />

          <Column
            className="text-center"
            field="count"
            header={
              <div className="d-flex align-items-center text-center gap-2 justify-content-center">
                <label className=" text-center">{isLang === "en" ? "Points" : "النقاط"}</label>
                <select className="form-select select-status text-center" value={sortOrder} onChange={handleSortChange}>
                  <option value=""> {isLang === "en" ? "All" : "الكل"}</option>
                  <option value="1"> {isLang === "en" ? "Ascending" : "ترتيب تصاعدي"} </option>
                  <option value="2"> {isLang === "en" ? "Descending" : "ترتيب تنازلي"}</option>
                </select>
              </div>
            }
          />
          <Column
            className="text-center"
            field="created_at"
            header={isLang === "en" ? "Added Date" : "تاريخ الإضافة "}
            body={rowData => {
              const date = new Date(rowData.created_at);
              const formattedDate = date.toLocaleDateString("en-GB");
              return <span>{formattedDate}</span>;
            }}
          />
        </DataTable>

        <Paginator
          ref={dtt.current}
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          currentPage={currentPage - 1}
        />
      </div>
    </>
  );
}
