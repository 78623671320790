import LogoSvg from "../../assets/svg/LogoSvg";
import {
  BiCategoryAlt,
  BiCartAlt,
  BiCog,
  BiDownload,
  BiStore,
  BiUser,
  BiBriefcase,
  BiCommentDetail,
  BiCommentError,
  BiCube,
  BiAlignMiddle
} from "react-icons/bi";
import { HiCubeTransparent } from "react-icons/hi";

const routes = [
  {
    path: "",
    nameEn: "Dashboard",
    nameAr: "الصفحة الرئيسية",
    icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />
  },

  {
    path: "/Inventory",
    nameEn: "Inventory",
    nameAr: "قسم الجرد",
    icon: <HiCubeTransparent className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/brands",
        nameEn: "Brands",
        nameAr: " العلامات التجارية"
      },
      {
        path: "/products",
        nameEn: "Products",
        nameAr: " المنتجات "
      },
      // {
      //   path: "/stores",
      //   nameEn: "Stores",
      //   nameAr: "المتجر"
      // },
      // {
      //   path: "/categories",
      //   nameEn: "Categories",
      //   nameAr: " الأقسام "
      // },
      {
        path: "/suggested-products",
        nameEn: "Suggested New Products",
        nameAr: "اقتراحات المنتجات الجديدة"
      },
      {
        path: "/suggested-edits",
        nameEn: "Suggested Edits",
        nameAr: " التعديلات المقترحة "
      },
    ]
  },
  {
    path: "/Reports",
    nameEn: " Reports",
    nameAr: " التقارير ",
    icon: <BiAlignMiddle className="logoSvg" style={{ width: 17 }} />,
    subRoutes: [
      {
        path: "/reports-users",
        nameEn: "Users Report",
        nameAr: " تقرير العملاء "
      },
      {
        path: "/reports-products",
        nameEn: "Products Report",
        nameAr: " تقرير المنتجات "
      },
      {
        path: "/reports-scanned",
        nameEn: "Scanned Report",
        nameAr: " تقرير مسح المنتجات"

      },
      {
        path: "/reports-suggested",
        nameEn: "Suggested Report",
        nameAr: " تقرير المنتجات المقترحة "
      },
    ]
  },
  {
    path: "/settings",
    nameEn: "Settings",
    nameAr: "الاعدادات",
    icon: <BiCog className="logoSvg" style={{ width: 17 }} />,

    subRoutes: [
      {
        path: "/settings",
        nameEn: "General Settings",
        nameAr: "  الاعدادات العامة "
      },
      {
        path: "/ststic-page",
        nameEn: "Ststic Page",
        nameAr: " البيانات الأساسية "
      },
      {
        path: "/admins",
        nameEn: "Admins",
        nameAr: " المدراء "
      }
    ]
  },
  {
    path: "/users",
    nameEn: "Users",
    nameAr: " العملاء ",
    icon: <BiUser className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/keywords",
    nameEn: "Additive Codes",
    nameAr: " الأكواد المضافة  ",
    icon: <BiDownload className="logoSvg" style={{ width: 17 }} />,
  },
  {
    path: "/complaints-messages",
    nameEn: "Complaints",
    nameAr: " الشكاوى ",
    icon: <BiCommentError className="logoSvg" style={{ width: 17 }} />
  },
  {
    path: "/reviws",
    nameEn: "Reviews",
    nameAr: " التعليقات ",
    icon: <BiCommentDetail className="logoSvg" style={{ width: 17 }} />
  }
];

export default routes;
