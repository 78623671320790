import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Link, Navigate, useNavigate } from "react-router-dom"
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import moment from 'moment';
import CountUp from 'react-countup';
import { VendersContext } from '../../context/Store';
import { BASE_URL } from '../../context/Enviroment';

const Users = (props) => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [currentPage, setCurrentPage] = useState(1); 
  const [products, setProducts] = useState([]);

  async function getUsers(page) {
    try {
      const token = localStorage.getItem("userToken");

      const headers = {
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.get(`${BASE_URL}/dashboard/users?page=${page}`, { headers });
      const data = response.data;

      setProducts(data.data.data);
      setTotalRecords(data.data.total);
    } catch (error) {
      console.error(error);
    }
  }

  // users search
  async function searchUsers(e, page = 1) { 
    const token = localStorage.getItem("userToken");
  
    const searchQuery = e.target.value ? `&search=${e.target.value}` : '';
    const url = `${BASE_URL}/dashboard/users?page=${page}${searchQuery}`;
  
    try {
      let { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      
      setProducts(data.data.data); 
      setTotalRecords(data.total); 
      setCurrentPage(page); 
    } catch (error) {
      console.error(error);
    }
  }
  
  useEffect(() => {


    getUsers(1);
  }, []);




  let emptyProduct = {
    name: ''

  };


  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const dtt = useRef(null);



  function onPageChange(event) {
    getUsers(event.page + 1);
  }










  const addedDateBodyTemplate = (rowData) => {
    const formattedDate = moment(rowData.created_at).format('DD-MM-YYYY');
    return <span>{formattedDate}</span>;
  };

  const pointsBodyTemplate = (rowData) => {
    return <span>{rowData.points}</span>;

  };





  const navigate = useNavigate();

  const viewUserDetails = (product) => {
    setProduct({ id: product.id, name: product.name });
    setProductDialog(true);
    navigate(`/view-user/${product.id}`);
  };


  const blockUser = async (rowData) => {
    try {
      let newStatus;
      if (rowData.status == false) {
        toast.current.show({ severity: "success", summary: "Success", detail: "User Unblocked Successfully", life: 3000 });

        newStatus = 1;
      } else {
        toast.current.show({ severity: "success", summary: "Success", detail: "User Blocked Successfully", life: 3000 });


        newStatus = 0;
      }
      getUsers()
      const payload = {
        user_id: rowData.id,
        status: newStatus
      };
      const token = localStorage.getItem("userToken");

      // Send a POST request to the API endpoint to update the user status
      await axios.post(`${BASE_URL}/dashboard/users/change-status`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      getUsers()
      // Update the user row with the updated status

      const updatedUsers = [...products];
      const updatedUserIndex = updatedUsers.findIndex(user => user.id === rowData.id);
      if (updatedUserIndex !== -1) {
        updatedUsers[updatedUserIndex].active = newStatus;
        ////console.log(newStatus);
        setProducts(updatedUsers);

        // Store the updated user status in local storage
        localStorage.setItem(`user_${rowData.id}_status`, newStatus);
      }
    } catch (error) {
      ////console.log(error.response);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className='d-flex gap-4'>
          <Button icon="pi pi-eye" rounded outlined severity="info" onClick={() => viewUserDetails(rowData)} />
          <Button icon="pi pi-ban" rounded outlined className={`mr-2 text-light ${rowData.status == false? 'bg-danger' : 'bg-primary'}`} onClick={() => blockUser(rowData)} />        </div>
      </React.Fragment>
    );
  };
  const header = (
    <div className="flex d-flex flex-wrap gap-2 align-items-center justify-content-between">
      <div className='d-flex align-items-center '>

        <h3 className='p-2 '> {isLang === "en" ? "Total Users:" : "إجمالي المستخدمين:"}</h3>
        <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h3" />
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText onChange={searchUsers} type="search" onInput={(e) => searchUsers(e.target.value)} placeholder={isLang === "en" ? "Search..." : "البحث"} />
      </span>
    </div>
  );

 
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };



  

  return (

    <div>
      <Toast ref={toast} />
      <div className="card ">
        <DataTable
          value={products}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter}
          header={header}
        >
          <Column selectionMode="multiple" className="px-3" exportable={true}></Column>
          {/* <Column field="id" header="ID" style={{ minWidth: '12rem' }} ></Column> */}
          <Column field="name" header={isLang === "en" ? "User Name" : "اسم العميل"} style={{ minWidth: '16rem' }}></Column>
          <Column
            field="addedDate"
            header={isLang === "en" ? "Added Date" : "تاريخ الإضافة"}
            body={addedDateBodyTemplate}
            className="px-3"
            style={{ minWidth: '10rem' }}
          ></Column>

          <Column field="points" header={isLang === "en" ? "points " : "النقاط"}
            body={pointsBodyTemplate} className="px-3" style={{ minWidth: '10rem' }} />

          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <Paginator ref={dtt.current} totalRecords={totalRecords} rows={10} onPageChange={onPageChange}></Paginator>
      </div>



     

  
    </div>


  );
}

export default (Users);