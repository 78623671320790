import React, { useState, useEffect, useContext, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { VendersContext } from "../../../context/Store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Toast } from "primereact/toast";
import { Editor } from "primereact/editor";
import { BASE_URL } from "../../../context/Enviroment";
import { Button } from 'primereact/button';

const StaticPage = () => {
  const toast = useRef(null);
  let { isLang, setIsLang } = useContext(VendersContext);

  const [settings, setSettings] = useState({
    privacy_policy_de: "",
    privacy_policy_en: "",
    privacy_policy_ar: "",
    about_us_de: "",
    about_us_en: "",
    about_us_ar: "",
    terms_conditions_de: "",
    terms_conditions_en: "",
    terms_conditions_ar: "",
    faq_text_de: "",
    faq_text_en: "",
    faq_text_ar: ""
  });

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${BASE_URL}/dashboard/settings`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const data = await response.json();
      setSettings(data.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("userToken");
      const formattedSettings = {
        privacy_policy: {
          de: settings.privacy_policy_de,
          en: settings.privacy_policy_en,
          ar: settings.privacy_policy_ar
        },
        about_us: {
          de: settings.about_us_de,
          en: settings.about_us_en,
          ar: settings.about_us_ar
        },
        terms_conditions: {
          de: settings.terms_conditions_de,
          en: settings.terms_conditions_en,
          ar: settings.terms_conditions_ar
        },
        faq_text: {
          de: settings.faq_text_de,
          en: settings.faq_text_en,
          ar: settings.faq_text_ar
        }
      };

      const response = await fetch(`${BASE_URL}/dashboard/settings/update-static`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formattedSettings)
      });

      if (response.ok) {
        toast.current.show({ severity: "success", summary: "Success", detail: "updated successfully", life: 2000 });
        fetchSettings();
      } else {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update", life: 2000 });
      }
    } catch (error) {
      console.error("Error updating settings:", error);
    }
  };

  //handle editor header
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold" />
        <button className="ql-italic" aria-label="Italic" />
        <button className="ql-underline" aria-label="Underline" />
        <button className="ql-link" aria-label="Insert Link" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
    );
  };

  const header = renderHeader();

  return (
    <form onSubmit={handleSubmit} className="card rounded-4 p-4">
      <Toast ref={toast} />

      <TabView>
        <TabPanel header={isLang === "en" ? "Privacy Policy" : " سياسة الخصوصية"}>
          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "Privacy Policy (English)" : "سياسة الخصوصية (اللغة العربية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="privacy_policy_en"
            value={settings.privacy_policy_en}
            onTextChange={e => handleInputChange({ target: { name: "privacy_policy_en", value: e.htmlValue } })}
          />

          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "Privacy Policy (Germany)" : "سياسة الخصوصية (اللغة الالمانية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="privacy_policy_de"
            value={settings.privacy_policy_de}
            onTextChange={e => handleInputChange({ target: { name: "privacy_policy_de", value: e.htmlValue } })}
          />

          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "Privacy Policy (Arabic)" : "سياسة الخصوصية (اللغة العربية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="privacy_policy_ar"
            value={settings.privacy_policy_ar}
            onTextChange={e => handleInputChange({ target: { name: "privacy_policy_ar", value: e.htmlValue } })}
          />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "About Us" : " معلومات عنا"}>
          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "About Us (English)" : "معلومات عنا (اللغة الانجليزية)  "}
          </label>
          <Editor
            style={{ height: "150px" }}
            className="mt-3"
            id="about_us_en"
            value={settings.about_us_en}
            headerTemplate={header}
            onTextChange={e => handleInputChange({ target: { name: "about_us_en", value: e.htmlValue } })}
          />

          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "About Us (Germany)" : "معلومات عنا (اللغة الالمانية)  "}
          </label>
          <Editor
            style={{ height: "150px" }}
            className="mt-3"
            id="about_us_de"
            headerTemplate={header}
            value={settings.about_us_de}
            onTextChange={e => handleInputChange({ target: { name: "about_us_de", value: e.htmlValue } })}
          />

          <label htmlFor="privacy_policy_en" className="h6 mt-3">
            {isLang === "en" ? "About Us (Arabic)" : "معلومات عنا (اللغة العربية)  "}
          </label>
          <Editor
            style={{ height: "150px" }}
            className="mt-3"
            id="about_us_ar"
            headerTemplate={header}
            value={settings.about_us_ar}
            onTextChange={e => handleInputChange({ target: { name: "about_us_ar", value: e.htmlValue } })}
          />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Terms & Conditions" : "  القواعد والشروط"}>
          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Terms & Conditions (English)" : "القواعد والشروط (اللغة الانجليزية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="terms_conditions_en"
            value={settings.terms_conditions_en}
            onTextChange={e => handleInputChange({ target: { name: "terms_conditions_en", value: e.htmlValue } })}
          />

          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Terms & Conditions (Germany)" : "القواعد والشروط (اللغة الالمانية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="terms_conditions_de"
            value={settings.terms_conditions_de}
            onTextChange={e => handleInputChange({ target: { name: "terms_conditions_de", value: e.htmlValue } })}
          />

          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Terms & Conditions (Arabic)" : "القواعد والشروط (اللغة العربية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="terms_conditions_ar"
            value={settings.terms_conditions_ar}
            onTextChange={e => handleInputChange({ target: { name: "terms_conditions_ar", value: e.htmlValue } })}
          />
        </TabPanel>

        <TabPanel header={isLang === "en" ? "Faq" : " التعليمات "}>
          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Faq (English)" : " التعليمات (اللغة الانجليزية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="faq_text_en"
            value={settings.faq_text_en}
            onTextChange={e => handleInputChange({ target: { name: "faq_text_en", value: e.htmlValue } })}
          />

          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Faq (Germany)" : " التعليمات (اللغة الالمانية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="faq_text_de"
            value={settings.faq_text_de}
            onTextChange={e => handleInputChange({ target: { name: "faq_text_de", value: e.htmlValue } })}
          />

          <label htmlFor="terms_conditions_en" className="h6 mt-3">
            {isLang === "en" ? "Faq (Arabic)" : " التعليمات (اللغة العربية)  "}
          </label>
          <Editor
            headerTemplate={header}
            style={{ height: "150px" }}
            className="mt-3"
            id="faq_text_ar"
            value={settings.faq_text_ar}
            onTextChange={e => handleInputChange({ target: { name: "faq_text_ar", value: e.htmlValue } })}
          />
        </TabPanel>
      </TabView>
      <div className="w-1000 justify-content-center text-center mt-4 d-flex ">

      <Button type="submit" className="btn btn-primary justify-content-center mx-3 w-25">
        {isLang === "en" ? "Save" : "حفظ"}
      </Button>
      </div>
    </form>
  );
};

export default StaticPage;
