import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import axios from "axios";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../context/Enviroment";
import CustomPagination from "../../Components/Paginator/Paginator";

const AdminTable = () => {
  const [displayDialog, setDisplayDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const [admins, setAdmins] = useState([]);
  const [displayEditDialog, setDisplayEditDialog] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [adminData, setAdminData] = useState({}); // Initialize adminData with an empty object
  const [rolesData, setRolesData] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  let { isLang, setIsLang } = useContext(VendersContext);
  const toast = useRef(null);

  const [newAdminData, setNewAdminData] = useState({
    name: "",
    admin_email: "",
    role_id: [], // Initialize as an empty array
    password: ""
  });
  const [updatedAdminData, setUpdatedAdminData] = useState({
    name: "",
    email: "",
    role_id: "", // Initialize as an empty array
    password: ""
  });

  async function getRolesData() {
    try {
      const token = localStorage.getItem("userToken");
      const langChange = localStorage.getItem("langChange");
      const headers = {
        Authorization: `Bearer ${token}`,
        lang: langChange || "en" 
      };

      if (langChange === "de") {
        headers.lang = "de";
      } else if (langChange === "ar") {
        headers.lang = "ar";
      }

      const response = await axios.get(`${BASE_URL}/dashboard/roles`, { headers });
      const data = response.data.data;

      setRoles(data.data);

      ////console.log(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchAdmins = async page => {
    try {
      const token = localStorage.getItem("userToken");
      const langChange = localStorage.getItem("langChange");
      const headers = {
        Authorization: `Bearer ${token}`,
        lang: langChange
      };

      const response = await axios.get(`${BASE_URL}/dashboard/admins?page=${page}`, { headers });

      setAdmins(response.data.data.data);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const fetchAdminById = async adminId => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${BASE_URL}/dashboard/admins/${adminId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const admin = response.data.data;
      setAdminData(admin);
      setUpdatedAdminData(admin);
  
      if (admin.roles && admin.roles.length > 0) {
        setSelectedRole(admin.roles[0].id);
      } else {
        setSelectedRole(""); 
      }
    } catch (error) {
      console.error(`Error fetching admin with ID ${adminId}:`, error);
    }
  };
  
  async function searchAdmins(e, page) {
    if (e.target.value) {
      try {
        const token = localStorage.getItem("userToken");
        const lang = "en"; 

        const response = await axios.get(`${BASE_URL}/dashboard/admins?page=${page}&search=${e.target.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            lang: lang
          }
        });

        const { data } = response;
        setAdmins(data?.data?.data);
        ////console.log(data);
      } catch (error) {
        console.error("Error searching admins:", error);
      }
    } else {
      // Call the fetchAdmins function to reset the table
      fetchAdmins();
    }
  }

  useEffect(() => {
    fetchAdmins(currentPage);
    getRolesData();
  }, []);

  const handleCreateAdmin = () => {
    setDisplayDialog(true);
  };
  const handleCloseDialog = () => {
    setDisplayDialog(false);
    setNewAdminData({
      name: "",
      email: "",
      role_ids: [],
      password: ""
    });
  };

  const handleEdit = adminId => {
    ////console.log('Edit button clicked');
    fetchAdminById(adminId);
    setSelectedAdminId(adminId);
    setDisplayEditDialog(true);
  };

  const handleDelete = async adminId => {
    const confirmDelete = window.confirm("Are you sure you want to delete this admin?");
    if (!confirmDelete) {
      return;
    }

    try {
      const token = localStorage.getItem("userToken");
      await axios.delete(`${BASE_URL}/dashboard/admins/delete/${adminId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
       
      }); 
      fetchAdmins(currentPage);
      toast.current.show({ severity: "success", summary: "Success", detail: "Deleted Successfully", life: 3000 });
    } catch (error) {
      console.error("Error deleting admin:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to delete this admin", life: 3000 });
    }
  };

  const handleSaveAdmin = async () => {
    try {
      const token = localStorage.getItem("userToken");

      const payload = {
        name: newAdminData.name,
        email: newAdminData.admin_email,
        role_ids: [newAdminData.role_id.length > 0 ? newAdminData.role_id[0].id : null],
        password: newAdminData.password
      };

      const response = await axios.post(`${BASE_URL}/dashboard/admins/create`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Handle the response as needed
      ////console.log('Admin created:', response.data);
      toast.current.show({ severity: "success", summary: "Success", detail: "Created Successfully", life: 3000 });
      fetchAdmins();

      // Close the dialog and reset the form
      handleCloseDialog();
    } catch (error) {
      console.error("Error creating admin:", error);
      // Handle the error condition
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add this admin", life: 3000 });
    }
  };
  const handleCancel = () => {
    setDisplayDialog(false);
    setNewAdminData({
      name: "",
      admin_email: "",
      role_id: [], 
      password: ""
    });
  };
  const handleUpdateAdminData = async () => {
    try {
      const payload = {
        role_ids: [selectedRole ? selectedRole : null],
        name:updatedAdminData.name,
        email:updatedAdminData.admin_email,
        password:updatedAdminData.password

      };
      const token = localStorage.getItem("userToken");

      const response = await axios.post(`${BASE_URL}/dashboard/admins/update/${selectedAdminId}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      fetchAdmins();
      toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully", life: 3000 });

      setDisplayEditDialog(false);

      // Handle the response from the backend as needed
    } catch (error) {
      console.error("Error updating admin data:", error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update", life: 3000 });
    }
  };
  const handleNameChange = e => {
    setUpdatedAdminData(prevState => ({
      ...prevState,
      name: e.target.value
    }));
  };

  const handleEmailChange = e => {
    setUpdatedAdminData(prevState => ({
      ...prevState,
      admin_email: e.target.value
    }));
  };

  const handleRoleIdChange = e => {
    setUpdatedAdminData(prevState => ({
      ...prevState,
      role_id: e.target.value
    }));
  };

  const handlePasswordChange = e => {
    setUpdatedAdminData(prevState => ({
      ...prevState,
      password: e.target.value
    }));
  };
  const handleCloseEditDialog = () => {
    setDisplayEditDialog(false);
  };

  // handle pagination
  const [currentPage, setCurrentPage] = useState(1);

  const [rowsPerPage] = useState(10);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = admins.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(admins.length / rowsPerPage);
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  return (
    <div>
      <Toast ref={toast} />

      <div className="flex mb-3 d-flex px-2 flex-wrap gap-2 align-items-center justify-content-between">
        <Button
          label={isLang === "en" ? "Add Admin" : "إضافة مدير"}
          severity="success"
          className="rounded-3"
          icon="pi pi-plus"
          onClick={handleCreateAdmin}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            onChange={searchAdmins}
            type="search"
            onInput={e => searchAdmins(e?.target?.value)}
            placeholder={isLang === "en" ? "Search..." : "البحث"}
          />
        </span>
      </div>

      <div>
        <table className="table pt-2">
          <thead className="table-light">
            <tr>
              <th scope="col">{isLang === "en" ? "Role ID" : "الرقم"}</th>
              <th scope="col">{isLang === "en" ? "Name" : "الاسم"}</th>
              <th scope="col">{isLang === "en" ? "Email" : "الايميل"}</th>
              <th scope="col">{isLang === "en" ? "Role" : "الوظيفة"}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody className="text-center">
            {currentData.map(admin => (
              <tr className="role-tr" key={admin.id}>
                <td>{admin.id}</td>
                <td>{admin.name}</td>
                <td>{admin.admin_email}</td>
                <td>{admin?.roles[0]?.name}</td>
                <td className="d-flex gap-2">
                  <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => handleEdit(admin.id)} />

                  <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleDelete(admin.id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          indexOfLastRow={indexOfLastRow}
          reportData={admins}
        />
      </div>
      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        header={isLang === "en" ? "Add Admin" : "إضافة مدير"}
        visible={displayDialog}
        onHide={handleCancel}
        style={{ width: "45rem", height: "450px" }}
      >
        <div className="p-grid">
          <div className="p-col- d-flex gap-3 mb-2">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Name" : "الاسم"}</label>
            <InputText
              className="w-100"
              value={newAdminData.name}
              onChange={e => setNewAdminData({ ...newAdminData, name: e.target.value })}
            />
          </div>
          <div className="p-col-4 d-flex gap-3 mb-2">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Email" : "الايميل"}</label>
            <InputText
              className="w-100"
              value={newAdminData.admin_email}
              onChange={e => setNewAdminData({ ...newAdminData, admin_email: e.target.value })}
            />
          </div>

          <div className="p-col-4 d-flex gap-3 mb-4">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Password" : "كلمة السر"}</label>
            <InputText
              className="w-100"
              value={newAdminData.password}
              onChange={e => setNewAdminData({ ...newAdminData, password: e.target.value })}
            />
          </div>
        </div>
        <div className="p-col-4 d-flex gap-3 mb-4">
          <label className="font-bold h5 d-flex w-25 align-items-center"> {isLang === "en" ? "Role" : "الوظيفة"}</label>

          <MultiSelect
            className="w-100 align-items-center"
            value={Array.isArray(newAdminData.role_id) ? newAdminData.role_id : [newAdminData.role_id]}
            options={roles}
            onChange={e => {
              setNewAdminData({ ...newAdminData, role_id: e.value });
            }}
            optionLabel="name"
            placeholder={isLang === "en" ? "Select Role" : "حدد الوظيفة"}
          />
        </div>
        <div className="p-dialog-footer text-center ">
          <Button
            label={isLang === "en" ? "Save" : "حفظ"}
            icon="pi pi-check"
            severity="success"
            className="rounded-3 p-button"
            onClick={handleSaveAdmin}
          />

          <Button
            label={isLang === "en" ? "Cancel" : "إلغاء"}
            icon="pi pi-times"
            severity="danger"
            className="rounded-3 p-button-secondary"
            onClick={handleCancel}
          />
        </div>
      </Dialog>
      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        header="Edit Admin"
        visible={displayEditDialog}
        onHide={handleCloseEditDialog}
        style={{ width: "45rem", height: "450px" }}
      >
        <div className="p-grid">
          <div className="p-col-4 d-flex gap-3 mb-3 align-items-center">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Name" : "الاسم"}</label>

            <InputText className="w-100" label="Name" value={updatedAdminData.name || ""} onChange={handleNameChange} />
          </div>

          <div className="p-col-4 d-flex gap-3 mb-3">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Email" : "الايميل"}</label>

            <InputText className="w-100" label="Email" value={updatedAdminData.admin_email || ""} onChange={handleEmailChange} />
          </div>

          <div className="p-col-4 d-flex gap-3 mb-3">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Password" : "كلمة السر"}</label>

            <InputText
              className="w-100"
              label="Password"
              value={updatedAdminData.password || ""}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="p-col-4 d-flex gap-3 mb-3">
            <label className="font-bold h5 d-flex w-25 align-items-center">{isLang === "en" ? "Role" : "الوظيفة "}</label>

            <select
              className="form-select w-100 form-select-lg mb-3"
              aria-label=".form-select-lg example"
              value={selectedRole}
              onChange={e => setSelectedRole(e.target.value)}
            >
              <option value="">Select Role</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="text-center p-dialog-footer">
          <Button
            label={isLang === "en" ? "Save" : " حفظ"}
            icon="pi pi-check"
            severity="success"
            className="rounded-3 p-button"
            onClick={handleUpdateAdminData}
          />

          <Button
            label={isLang === "en" ? "Cancel" : "إلغاء"}
            icon="pi pi-times"
            severity="danger"
            className="rounded-3 p-button-secondary"
            onClick={handleCloseEditDialog}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default AdminTable;
