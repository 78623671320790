import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BASE_URL } from "../../../context/Enviroment";
export const handelLogin = async (values, setLoadEmail, navigate) => {
  const url = `${BASE_URL}/dashboard/login`;

  try {
    setLoadEmail(true);
    let { data } = await axios.post(url, values).catch(({ response }) => {
      if (response.data.message) {
        toast.error(response.data?.errors?.email[0]);
      }

      if (response.data.msg) {
        toast.error(response.data.msg);
      }

      setLoadEmail(false);
    });
    if (data.status === true) {
      toast.success("Success");
      localStorage.setItem("userToken", data.data.token);
      localStorage.setItem("UserName", data.data.name);
      navigate("/");
      setLoadEmail(false);
    } else {
      setLoadEmail(false);
    }
  } catch (error) {
    console.error(error.response.data);
    if (error.response.data.StatusCode === "Error") {
      toast.error(error.response.data.Message);
    } else {
      toast.error("An error occurred. Please try again later.");
    }
    setLoadEmail(false);
  }
};
