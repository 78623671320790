import React, { useContext, useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

import { VendersContext } from "../../context/Store";
import { Island } from "mdi-material-ui";
import { BASE_URL } from "../../context/Enviroment";

const formatData = (usersData, productsData, products_scanned_chart) => {
  const labels = usersData.map(d => d.full_date);

  return {
    labels,
    datasets: [
      {
        label: "Totla Users",
        data: usersData.map(d => d.count),
        backgroundColor: "#FFDBAA",
        borderColor: "#FFDBAA",
        fill: false
      },
      {
        label: "Total Products",
        data: productsData.map(d => d.count),
        backgroundColor: "#609966",
        borderColor: "#609966",
        fill: false
      },
      {
        label: "Scanned Products",
        data: products_scanned_chart.map(d => d.count),
        backgroundColor: "#FF9B9B",
        borderColor: "#FF9B9B",
        fill: false
      }
    ]
  };
};

const LineChart = () => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [chartData, setChartData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const defaultStartDate = new Date();
    defaultStartDate.setDate(1);
    const defaultEndDate = new Date(defaultStartDate.getFullYear(), defaultStartDate.getMonth() + 1, 0);
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);

    fetchData(defaultStartDate, defaultEndDate);
  }, []);

  const fetchData = async (start, end) => {
    if (!start || !end) {
      return;
    }

    const startDateStr = formatDate(start);
    const endDateStr = formatDate(end);

    try {
      const token = localStorage.getItem("userToken");

      const response = await fetch(
        `${BASE_URL}/dashboard/reports/statistics?start_date=${startDateStr}&end_date=${endDateStr}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const data = await response.json();
      setChartData(formatData(data.data.users_chart, data.data.products_chart, data.data.products_scanned_chart));
      //console.log(data.data.users_chart);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDate = date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
  };

  const handleSearch = () => {
    fetchData(startDate, endDate);
  };

  const handleStartDateChange = e => {
    setStartDate(e.value);
  };

  const handleEndDateChange = e => {
    setEndDate(e.value);
  };

  return (
    <div>
      <div dir={isLang == "en" ? "ltr" : "rtl"} className=" p-4 mb-4 d-flex flex-row justify-content-between">
        <div className="d-flex flex-row align-items-center gap-3">
          <h6> {isLang === "en" ? "From:" : "من:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            value={startDate}
            onChange={handleStartDateChange}
            placeholder="Enter start date"
            showIcon
            iconPos="left"
          />
        </div>
        <div className="d-flex flex-row align-items-center gap-3">
          <h6> {isLang === "en" ? "To:" : "إلى:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            value={endDate}
            onChange={handleEndDateChange}
            placeholder="Enter end date"
            showIcon
            iconPos="left"
          />
        </div>
        <Button className="search-btn" label={isLang === "en" ? "Search" : "بحث"} onClick={handleSearch} />
      </div>
      <Chart dir={isLang == "en" ? "ltr" : "rtl"} type="line" data={chartData} />
    </div>
  );
};

export default LineChart;
