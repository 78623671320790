import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import { handelLogin } from "../Services/AuthService";
import SidebarLogo from "../../../assets/Images/logo/logo-dark.png";
import logo from "../../../assets/Images/halalakLogo.png"
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner";

function Login() {
  const langChange = localStorage.getItem("langChange");

  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loadEmail, setLoadEmail] = useState(false);
  const navigate = useNavigate();
  const [loginInProgress, setLoginInProgress] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    if (loginInProgress) {
      return;
    }

    setLoginInProgress(true);

    const loginData = {
      email: phoneNumber,
      password: password
    };

    try {
      await handelLogin(loginData, setLoadEmail, navigate);
      setTimeout(() => setLoginInProgress(false), 3000);
    } catch (error) {
      setLoginInProgress(false);
    }
  };

  return (
    <div className="row  halalk-login justify-content-center bg-light  rounded-3 ">
 
      <div className="  h-100  d-flex flex-column justify-content-center align-items-center  ">
        <div className="  d-flex flex-column justify-content-center align-items-center login-container halalk-login p-4">
          <div
            dir={langChange == "en" ? "ltr" : "rtl"}
            className="d-flex flex-column text-center align-items-center gap-3 mb-4 mt-4"
          >
            <img src={logo} width="180px" height="160px" />
            <span className="loginMessage-login h6">Welcome To Halalak Dashboard
            <hr></hr>
             {t("loginMessage")}</span>
          </div>

          <form dir={langChange == "en" ? "ltr" : "rtl"} onSubmit={handleSubmit} className="d-flex flex-column gap-3 mb-4">
            <div>
              <h6 className="form-label " htmlFor="phoneNumber">
                {t("email")}*
              </h6>
              <input
                type="text"
                id="phoneNumber"
                value={phoneNumber}
                className="login-input px-2"
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <h6 className="form-label" htmlFor="password" dir={langChange == "en" ? "ltr" : "rtl"}>
                {t("password")}*
              </h6>
              <input
                type="password"
                id="password"
                value={password}
                className="login-input  px-2"
                onChange={e => setPassword(e.target.value)}
              />
             
            </div>
            <div>
              <Button
                className="rounded-3 login-btn justify-content-center"
                severity="info"
                type="submit"
                onClick={handleSubmit}
                disabled={loginInProgress}
              >
                {loadEmail ? (
                  <ProgressSpinner style={{ width: "30px", height: "30px" }} strokeWidth="6" animationDuration=".5s" />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
