import axios from "axios";
import { useContext, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import { BASE_URL } from "../../context/Enviroment";
import { Checkbox } from "primereact/checkbox";
import Loading from "../../Components/Loading/Loading";
import Swal from "sweetalert2";

export default function ComplaintsMessages() {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [showDialog, setShowDialog] = useState(false);
  const options = ["Off", "On"];
  const [buttonValue, setButtonValue] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameDe, setNameDe] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const [editingMessage, setEditingMessage] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useRef(null);

  const handleSubmit = () => {
    const token = localStorage.getItem("userToken");

    const data = {
      has_description: buttonValue ? 1 : 0,
      content: {
        en: nameEn,
        de: nameDe,
        ar: nameAr
      }
    };

    axios
      .post(`${BASE_URL}/dashboard/complaint-messages/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        // Handle success
        toast.current.show({ severity: "success", summary: "Success", detail: "Added Successfully", life: 3000 });
        setShowDialog(false); // Close the dialog
        fetchMessages();
      })
      .catch(error => {
        // Handle error
        console.error(error);
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed to add", life: 3000 });
      });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const updatedMessage = {
        has_description: editingMessage.has_description,
        content: {
          en: nameEn,
          de: nameDe,
          ar: nameAr
        }
      };

      const response = await axios.post(
        `${BASE_URL}/dashboard/complaint-messages/update/${editingMessage.id}`,
        updatedMessage,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      toast.current.show({ severity: "success", summary: "Success", detail: "Updated Successfully", life: 3000 });

      setEditingMessage(null);
      fetchMessages();
    } catch (error) {
      console.error(error);
      toast.current.show({ severity: "error", summary: "Error", detail: "Failed to update", life: 3000 });
    }
  };

  const handleEditDialogOpen = message => {
    setEditingMessage(message);
    setShowEditDialog(true);
    //console.log(message);
    // Set initial values of input fields
    setNameEn(message.translations.find(t => t.locale === "en").content);
    setNameDe(message.translations.find(t => t.locale === "de").content);
    setNameAr(message.translations.find(t => t.locale === "ar").content);
  };
  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "has_description") {
      value = e.target.checked ? 1 : 0;
    }
    setEditingMessage(prevEditingMessage => ({
      ...prevEditingMessage,
      [field]: value
    }));
  };

  const fetchMessages = async currentPage => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${BASE_URL}/dashboard/complaint-messages?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          lang: selectedLanguage
        }
      });
      const { messages, total } = response.data.data;
      setMessages(response.data.data);
      setTotalPages(Math.ceil(total / 10)); // Assuming 10 messages per page
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditDialogClose = () => {
    setShowEditDialog(false);
    setEditingMessage(null);
  };
  const handleButtonChange = e => {
    setButtonValue(e.target.checked);
  };

  const handleButtonClick = () => {
    handleSubmit();
  };
  ////console.log(editingMessage)
  useEffect(
    () => {
      fetchMessages(currentPage);
    },
    [currentPage, selectedLanguage]
  );

  const handlePagination = page => {
    setCurrentPage(page);
  };
  const handleDialogOpen = () => {
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDelete = async messageId => {
    const token = localStorage.getItem("userToken");

    Swal.fire({
      title: isLang === "en" ? "Confirmation" : "تأكيد",
      text: isLang === "en" ? "Are you sure you want to delete this message?" : "هل أنت متأكد أنك تريد حذف هذه الرسالة؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: isLang === "en" ? "Yes, delete it" : "نعم أريد المسح",
      cancelButtonText: isLang === "en" ? "Cancel" : "إلغاء"
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${BASE_URL}/dashboard/complaint-messages/delete/${messageId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          });
          toast.current.show({ severity: "success", detail: "Deleted Successfully", life: 3000 });
          fetchMessages();
        } catch (error) {
          console.error("Error deleting message:", error);
          toast.current.show({ severity: "error", detail: "Failed to delete this message", life: 3000 });
        }
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        style={{ width: "35rem", height: "400px" }}
        header={isLang === "en" ? "Add New Complaint Message" : "إضافة رسالة شكوى جديدة"}
      >
        <div>
          <TabView>
            <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_en" className="font-bold input-panels-complaints">
                {isLang === "en" ? "EN_Name" : "الاسم"}
              </h6>
              <InputText id="name_en" className="w-100" value={nameEn} onChange={e => setNameEn(e.target.value)} />
            </TabPanel>
            <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_ar" className="font-bold input-panels-complaints">
                {isLang === "en" ? "AR_Name" : " الاسم"}
              </h6>
              <InputText className="w-100" id="name_ar" value={nameAr} onChange={e => setNameAr(e.target.value)} />
            </TabPanel>
            <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"} className="d-flex gap-4 ">
              <h6 htmlFor="name_de" className="font-bold input-panels-complaints">
                {isLang === "en" ? "DE_Name" : "الاسم "}
              </h6>
              <InputText className="w-100" id="name_de" value={nameDe} onChange={e => setNameDe(e.target.value)} />
            </TabPanel>
          </TabView>

          <div className="justify-content-between d-flex mb-3">
            <h6>{isLang === "en" ? "Allow user to write a description here" : "السماح للمستخدم بكتابة وصف للشكوى"}</h6>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="langSwitch"
                checked={buttonValue}
                onChange={handleButtonChange}
              />
              <label className="custom-control-label" htmlFor="langSwitch">
                {buttonValue ? options[1].label : options[0].label}
              </label>
            </div>
          </div>
          <div className="p-dialog-footer">
            <Button label="Save" onClick={handleSubmit} />
          </div>
        </div>
      </Dialog>
      <Dialog
        dir={isLang == "en" ? "ltr" : "rtl"}
        visible={showEditDialog}
        onHide={handleEditDialogClose}
        style={{ width: "37rem", height: "420px" }}
        header={isLang === "en" ? "Edit Message" : "تعديل الرسالة"}
      >
        {editingMessage && (
          <div>
            <TabView>
              <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"} className="d-flex gap-4 ">
                <h6 htmlFor="name_en" className="edit-label-complaints font-bold input-panels-complaints">
                  {isLang === "en" ? "EN_Name" : "الاسم"}
                </h6>
                <InputText className="w-100" id="name_en" value={nameEn} onChange={e => setNameEn(e.target.value)} />
              </TabPanel>
              <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"} className="d-flex gap-4 ">
                <h6 htmlFor="name_de" className="edit-label-complaints font-bold input-panels-complaints">
                  {isLang === "en" ? "DE_Name" : "الاسم "}
                </h6>
                <InputText id="name_de" className="w-100" value={nameDe} onChange={e => setNameDe(e.target.value)} />
              </TabPanel>
              <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"} className="d-flex gap-4 ">
                <h6 htmlFor="name_ar" className="edit-label-complaints font-bold input-panels-complaints">
                  {isLang === "en" ? "AR_Name" : " الاسم"}
                </h6>
                <InputText id="name_ar" className="w-100" value={nameAr} onChange={e => setNameAr(e.target.value)} />
              </TabPanel>
            </TabView>

            <div className="d-flex gap-4 p-3">
            <h6> {isLang === "en" ? "Allow user to write a description here" : "السماح للمستخدم بكتابة وصف للشكوى"}</h6>

              <div>
                <input
                  type="checkbox"
                  checked={editingMessage.has_description === 1}
                  onChange={e => handleInputChange(e, "has_description")}
                />
              </div>
            </div>
            <div className="d-flex gap-4 pt-3">
              <Button
                label="Save"
                onClick={() => {
                  handleUpdate();
                  handleEditDialogClose();
                }}
              />
              <Button label="Cancel" onClick={handleEditDialogClose} />
            </div>
          </div>
        )}
      </Dialog>
      <div className="card rounded-4 p-3">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div className="flex mb-3 d-flex px-2  flex-wrap gap-2 align-items-center justify-content-between">
              <Button
                label={isLang === "en" ? "Add Message" : "إضافة رسالة"}
                onClick={handleDialogOpen}
                icon="pi pi-plus"
                severity="success"
                className="rounded-3"
              />
            </div>
            <div>
              <table className="table pt-2">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{isLang === "en" ? "ID" : "الرقم"}</th>
                    <th scope="col">{isLang === "en" ? "Message" : "الرسالة"}</th>
                    <th scope="col">{isLang === "en" ? "Has Description" : "وصف الرسالة"}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="text-center">
                  {messages.map(message => (
                    <tr className="role-tr" key={message.id}>
                      <td>{message.id}</td>
                      {editingMessage && editingMessage.id === message.id ? (
                        <td>
                          <InputText
                            value={editingMessage.message}
                            onChange={e => setEditingMessage({ ...editingMessage, message: e.target.value })}
                          />
                        </td>
                      ) : (
                        <td>{message.content}</td>
                      )}
                      <td>{message.has_description === 1 ? "Yes" : "No"}</td>
                      <td>
                        <Button
                          icon="pi pi-pencil"
                          rounded
                          outlined
                          className="mr-2"
                          onClick={() => handleEditDialogOpen(message)}
                        />
                        <Button
                          icon="pi pi-trash"
                          rounded
                          outlined
                          severity="danger"
                          onClick={() => handleDelete(message.id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div>
                {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                  <button key={page} onClick={() => handlePagination(page)}>
                    {page}
                  </button>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
