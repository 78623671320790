import React, { useState, useEffect, useContext, useRef } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CompanyInfo from "./CompanyInfo";
import ComplaintsMessages from "./ComplaintsMessages";
import { VendersContext } from "../../context/Store";
import { Toast } from "primereact/toast";
import axios from "axios";
import Select from "react-select";
import { Image } from "primereact/image";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../context/Enviroment";
import Points from "./Points";
import Pixels from "./Pixels";

const SettingForm = () => {
  let { isLang } = useContext(VendersContext);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const { t } = useTranslation();

  const toast = useRef(null);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    image: "",
    phone: "",
    old_password: "",
    new_password: ""
  });

  const [notificationData, setNotificationData] = useState({
  
    title_en: "",
    title_de: "",
    title_ar: "",
    body_en: "",
    body_de: "",
    body_ar: "",
    url: "",
    picture: null
  });

  const handleNotificationChange = (e) => {
    const { name, value } = e.target;
    setNotificationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNotificationSend = async e => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("headings[en]", notificationData.title_en);
    formData.append("headings[de]", notificationData.title_de);
    formData.append("headings[ar]", notificationData.title_ar);
    formData.append("contents[en]", notificationData.body_en);
    formData.append("contents[de]", notificationData.body_de);
    formData.append("contents[ar]", notificationData.body_ar);
    formData.append("url", notificationData.url);
    formData.append("product_code", selectedProductCode);

    if (notificationData.picture) {
      formData.append("picture", notificationData.picture);
    }

    try {
      const token = localStorage.getItem("userToken");
      const response = await fetch(`${BASE_URL}/dashboard/notifications/send`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (response.ok) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Notification sent successfully!",
          life: 3000
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to send notification",
          life: 3000
        });
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };
  const handlePictureChange = e => {
    const file = e.target.files[0];
    setNotificationData(prevData => ({
      ...prevData,
      picture: file
    }));
  };
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedProductCode, setSelectedProductCode] = useState("");

  useEffect(
    () => {
      if (inputValue.trim() !== "") {
        searchProduct(inputValue);
      } else {
        setProducts([]);
      }
    },
    [inputValue, selectedLanguage]
  );
  const searchProduct = async inputValue => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${BASE_URL}/dashboard/products?search=${inputValue}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          lang: selectedLanguage
        }
      });

      const filteredProducts = response.data.data.data.map(product => ({
        value: product.id,
        label: `${product.name} (${product.barcode})`,
        code: product.barcode
      }));

      setProducts(filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const onProductSelect = selectedOption => {
    setSelectedProduct(selectedOption);
    setSelectedProductCode(selectedOption ? selectedOption.code : "");
  };

  useEffect(() => {
    searchProduct();
  }, []);
  return (
    <>
      <Toast ref={toast} />
      <TabView className="card rounded-4 p-4 m-3">
        <TabPanel header={isLang === "en" ? "Company Information" : "بيانات الشركة"}>
          <div>
            <CompanyInfo />
          </div>
        </TabPanel>

        <TabPanel header={t("notifications")}>
          <form onSubmit={handleNotificationSend}>
            <TabView>
              <TabPanel header={t("englishTab")} >
                <div>
                  <label htmlFor="title_en" className="h6 mt-3">
                    {t("notificationHeader")}
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    id="title_en"
                    name="title_en"
                    value={notificationData.title_en}
                    onChange={handleNotificationChange}
                  />
                </div>
                <div>
                  <label htmlFor="body_en" className="h6 mt-3">
                    {t("notificationBody")}
                  </label>
                  <textarea
                    className="form-control form-control-lg mb-3"
                    id="body_en"
                    name="body_en"
                    value={notificationData.body_en}
                    onChange={handleNotificationChange}
                  />
                </div>
              </TabPanel>
              <TabPanel header={isLang === "en" ? "Germany" : "اللغة الالمانية"} >
                <div>
                  <label htmlFor="title_de" className="h6 mt-3">
                    {t("notificationHeader")}
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    id="title_de"
                    name="title_de"
                    value={notificationData.title_de}
                    onChange={handleNotificationChange}
                  />
                </div>

                <div>
                  <label htmlFor="body_de" className="h6 mt-3">
                    {t("notificationBody")}
                  </label>
                  <textarea
                    className="form-control form-control-lg mb-3"
                    id="body_de"
                    name="body_de"
                    value={notificationData.body_de}
                    onChange={handleNotificationChange}
                  />
                </div>
              </TabPanel>
              <TabPanel header={t("arabicTab")}>
                <div>
                  <label htmlFor="title_ar" className="h6 mt-3">
                    {isLang === "en" ? "Title AR" : "الاسم"}
                  </label>
                  <input
                    className="form-control form-control-lg"
                    type="text"
                    id="title_ar"
                    name="title_ar"
                    value={notificationData.title_ar}
                    onChange={handleNotificationChange}
                  />
                </div>
                <div>
                  <label htmlFor="body_ar" className="h6 mt-3">
                    {t("notificationBody")}
                  </label>
                  <textarea
                    className="form-control form-control-lg mb-3"
                    id="body_ar"
                    name="body_ar"
                    value={notificationData.body_ar}
                    onChange={handleNotificationChange}
                  />
                </div>
              </TabPanel>
            </TabView>

            {/* add product */}
            <div className="mb-3 px-3">
              <label className="h6 mt-3 info-label" htmlFor="productSelect">
                {t("selectProduct")}
              </label>
              <Select
                id="productSelect"
                value={selectedProduct}
                onChange={onProductSelect}
                options={products}
                onInputChange={value => setInputValue(value)}
                placeholder="Search and select a product"
                isClearable
              />
            </div>

            <div className="mb-3 px-3">
             
              <div className="d-flex flex-row justify-content-between mt-3">
                <label className="h6 mt-3 info-label" htmlFor="imgUrl">
                  {t("addImg")}
                  <input className="form-control" type="file" onChange={handlePictureChange} />
                </label>
                {notificationData.picture && (
                  <div>
                    <Image src={URL.createObjectURL(notificationData.picture)} width="90px" height="100px" preview />
                  </div>
                )}
              </div>
            </div>
            <div className="w-1000 justify-content-center text-center mt-4 d-flex ">
              <button type="submit" className="btn btn-primary justify-content-center mx-3 w-25">
                {t("sendNotifications")}
              </button>
            </div>
          </form>
        </TabPanel>

        <TabPanel header={isLang === "en" ? "Points" : " النقاط"}>
          <Points />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Pixels" : " الاعلانات"}>
          <Pixels />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Complaints" : " الشكاوى"}>
          <ComplaintsMessages />
        </TabPanel>
        <TabPanel header={isLang === "en" ? "Payment" : " الدفع"}> </TabPanel>
        <TabPanel header={isLang === "en" ? "Subscriptions" : " الاشتراكات"}> </TabPanel>
      </TabView>
    </>
  );
};

export default SettingForm;
