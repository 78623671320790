import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import CountUp from "react-countup";
import { VendersContext } from "../../context/Store";

import { Calendar } from "primereact/calendar";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../context/Enviroment";

const UsersTable = () => {
  let { isLang, setIsLang } = useContext(VendersContext);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState("");
  const [sortApprovedOrder, setSortApprovedOrder] = useState("");
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const dtt = useRef(null);

  const fetchData = () => {
    const token = localStorage.getItem("userToken");

    let url = `${BASE_URL}/dashboard/users?page=${page}`;

    if (sortOrder) {
      const sortBy = "points_number";
      const sortType = sortOrder === "1" ? "asc" : "desc";

      url += `&sort_by=${sortBy}&sort_direction=${sortType}`;
    }
    if (sortApprovedOrder) {
      const sortBy = "approved_products";
      const sortType = sortOrder === "1" ? "asc" : "desc";

      url += `&sort_by=${sortBy}&sort_direction=${sortType}`;
    }
    if (startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      url = `${BASE_URL}/dashboard/users?start_date=${formattedStartDate}&end_date=${formattedEndDate}&page=${page}`;
    }
    // Append to URL

    axios
      .get(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        setUsers(response?.data?.data.data);
        setTotalRecords(response?.data.data.total);
      })
      .catch(error => {
        ////console.log(error);
      });
  };

  useEffect(
    () => {
      fetchData(page);
    },
    [page, sortApprovedOrder || sortOrder]
  );

  const handleSortChange = event => {
    const selectedSortOrder = event.target.value;
    setSortOrder(selectedSortOrder);
    setPage(1);
  };

  const handleApprovedSortChange = event => {
    const selectedApprovedSortOrder = event.target.value;
    // Define `sortOrder` in the function's scope
    const sortOrder = selectedApprovedSortOrder;
    setSortApprovedOrder(sortOrder);
    setPage(1);
  };

  const handlePageChange = event => {
    setPage(event.target.value);
    fetchData(sortOrder);
  };
  const formatDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = event => {
    const newPage = event.page + 1; // Assuming the API pagination starts from 1
    setCurrentPage(newPage);
    ////console.log(currentPage);
    fetchData(newPage);
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page + 1);
  };

  const statusBodyTemplate = rowData => {
    let colorClass, label, disabled;

    if (rowData.status == false) {
      colorClass = "p-button-danger rounded-5";
      label = "Blocked";
      disabled = true;
    } else if (rowData.status == true) {
      colorClass = "p-button-success rounded-5";
      label = "Active";
      disabled = false;
    }

    return <Button label={label} className={`p-button-rounded ${colorClass} ststus-btn`} style={{width:'100px' , height:'40px'}} disabled={disabled} />;
  };
  const handleSearch = () => {
    fetchData();
  };
  return (
    <>
      <h3 className="mx-4 mt-2">{isLang === "en" ? "Users Report" : "تقرير العملاء"}</h3>
      <div className=" card p-4 mb-3 mx-4 rounded-4 mt-3 d-flex flex-row justify-content-between">

        <div className="d-flex flex-row align-items-center gap-3">
          <h6>{isLang === "en" ? "From:" : "من:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            placeholder={isLang === "en" ? "enter start date" : "تاريخ البداية"}
            value={startDate}
            onChange={e => setStartDate(e.value)}
            showIcon
            iconPos="left"
          />
        </div>
        <div className="d-flex flex-row align-items-center gap-3">
          <h6>{isLang === "en" ? "To:" : "إلى:"}</h6>
          <Calendar
            dir={isLang == "en" ? "ltr" : "ltr"}
            placeholder={isLang === "en" ? "enter start date" : "تاريخ النهاية"}
            value={endDate}
            onChange={e => setEndDate(e.value)}
            showIcon
            iconPos="left"
          />
        </div>
        <Button label={isLang === "en" ? "Search" : "البحث"} onClick={handleSearch} className="rounded-3 search-btn"  />
      </div>
      <div className="card p-2 mb-3 mx-4 rounded-4 ">
        <div className="d-flex align-items-center mx-4 ">
          <h5 className="p-2 ">{isLang === "en" ? "Total Users:" : "إجمالي العملاء"} </h5>
          <CountUp start={0} end={totalRecords} duration={4} separator="," className="mb-1 h5" />
        </div>
        <DataTable stripedRows  value={users} rows={rows} first={first}>
          <Column className="text-center" field="id" header={isLang === "en" ? "ID" : "الرقم"} />
          <Column
            className="text-center"
            field="name"
            header={isLang === "en" ? "Name" : "الاسم"}
            body={rowData => <Link to={`/view-user/${rowData?.id}`}>{rowData.name}</Link>}
          />
          <Column className="text-center" field="email" header={isLang === "en" ? "Email" : "الايميل"} />

          <Column
            className="text-center"
            field="points"
            header={
              <div className="d-flex align-items-center text-center gap-2 justify-content-center">
                <label className=" text-center">{isLang === "en" ? "Points" : "النقاط"}</label>
                <select className="form-select select-status text-center" value={sortOrder} onChange={handleSortChange}>
                  <option value=""> {isLang === "en" ? "All" : "الكل"}</option>
                  <option value="1"> {isLang === "en" ? "Ascending" : "ترتيب تصاعدي"} </option>
                  <option value="2"> {isLang === "en" ? "Descending" : "ترتيب تنازلي"}</option>
                </select>
              </div>
            }
          />
          <Column
            className="text-center"
            field="suggested_count"
            header={
              <div className="d-flex align-items-center text-center gap-2 justify-content-center">
                <label className=" text-center">{isLang === "en" ? "Approved" : "المنتجات المقبولة"}</label>
                <select
                  className="form-select select-status text-center"
                  value={sortApprovedOrder}
                  onChange={handleApprovedSortChange}
                >
                  <option value=""> {isLang === "en" ? "All" : "الكل"}</option>
                  <option value="1"> {isLang === "en" ? "Ascending" : "ترتيب تصاعدي"} </option>
                  <option value="2"> {isLang === "en" ? "Descending" : "ترتيب تنازلي"}</option>
                </select>
              </div>
            }
          />
          <Column
            className="text-center"
            field="status"
            header={isLang === "en" ? "Status" : "الحالة"}
            body={statusBodyTemplate}
          />
        </DataTable>
        <Paginator
          ref={dtt.current}
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          currentPage={currentPage - 1}
        />
      </div>
    </>
  );
};

export default UsersTable;
