import { Toaster } from "react-hot-toast";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import Component from "./constants/Component";
import VenderContext from "./context/Store";
import "./style/App.scss";
import SettingForm from "./Pages/Settings/Settings";
import StaticPage from "./Pages/Settings/StaticPage/StaticPage";
import Admins from "./Pages/Admins/Admins";
import UsersReport from "./Pages/Reports/UsersReport";
import ScannedReport from "./Pages/Reports/ScannedReport";
import ProductsReports from "./Pages/Reports/ProductsReports";
import Stores from "./Pages/Inventory/Stores";
import Categories from "./Pages/Inventory/Categories";
import Products from "./Pages/Inventory/Products";
import EditProduct from "./Pages/Inventory/EditProduct";
import AddProduct from "./Pages/Inventory/AddProduct";
import ViewSuggested from "./Pages/Inventory/ViewSuggested";
import Brands from "./Pages/Inventory/Brands";
import SuggestedProducts from "./Pages/Inventory/SuggestedProducts";
import Reviews from "./Pages/Reviews/Reviews";
import Complaints from "./Pages/Complaints/Complaints";
import Additive_Codes from "./Pages/Additive_Codes/Additive_Codes";
import Users from "./Pages/Users/Users";
import ViewUser from "./Pages/Users/ViewUser";
import SuggestedReport from "./Pages/Reports/SuggestedReport";
import SuggestedEdits from "./Pages/Inventory/SuggestedEdits";
import SuggestedEditsDetails from "./Pages/Inventory/SuggestedEditsDetails";

function App() {
  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem("userToken");
    if (!token) {
      return <Navigate to="/admin/login" replace={true} />;
    } else {
      return <>{children}</>;
    }
  }

  const root = createHashRouter([
    {
      path: "/",
      element: <Component.Vendor />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoutes>
              <Component.Dashboard />
            </ProtectedRoutes>
          )
        },
        {
          path: "/contact",
          element: (
            <ProtectedRoutes>
              <Component.Contact />
            </ProtectedRoutes>
          )
        },
        {
          path: "home",
          element: (
            <ProtectedRoutes>
              <Component.Home />
            </ProtectedRoutes>
          )
        },

        { path: "profile", element: <Component.Profile /> },
        {
          path: "*",
          element: (
            <ProtectedRoutes>
              <Component.Error />
            </ProtectedRoutes>
          )
        },
        {
          path: "/settings",
          element: (
            <ProtectedRoutes>
              <SettingForm />
            </ProtectedRoutes>
          )
        },
        {
          path: "/ststic-page",
          element: (
            <ProtectedRoutes>
              <StaticPage />
            </ProtectedRoutes>
          )
        },
        {
          path: "/users",
          element: (
            <ProtectedRoutes>
              {" "}
              <Users />{" "}
            </ProtectedRoutes>
          )
        },
        {
          path: "/view-user/:id",
          element: (
            <ProtectedRoutes>
              {" "}
              <ViewUser />{" "}
            </ProtectedRoutes>
          )
        },

        {
          path: "/admins",
          element: (
            <ProtectedRoutes>
              <Admins />
            </ProtectedRoutes>
          )
        },
        //reports
        {
          path: "/reports-users",
          element: (
            <ProtectedRoutes>
              <UsersReport />
            </ProtectedRoutes>
          )
        },
        {
          path: "/reports-products",
          element: (
            <ProtectedRoutes>
              <ProductsReports />
            </ProtectedRoutes>
          )
        },
        {
          path: "/reports-scanned",
          element: (
            <ProtectedRoutes>
              <ScannedReport />
            </ProtectedRoutes>
          )
        },
        {
          path: "/suggested-edits",
          element: (
            <ProtectedRoutes>
              <SuggestedEdits />
            </ProtectedRoutes>
          )
        },
        {
          path: "/suggested-edits/:id",
          element: (
            <ProtectedRoutes>
              <SuggestedEditsDetails />
            </ProtectedRoutes>
          )
        },

        {
          path: "/reports-suggested",
          element: (
            <ProtectedRoutes>
              <SuggestedReport />
            </ProtectedRoutes>
          )
        },
        //inventory
        {
          path: "/categories",
          element: (
            <ProtectedRoutes>
              <Categories />
            </ProtectedRoutes>
          )
        },
        {
          path: "/stores",
          element: (
            <ProtectedRoutes>
              <Stores />
            </ProtectedRoutes>
          )
        },

        {
          path: "/products",
          element: (
            <ProtectedRoutes>
              <Products />
            </ProtectedRoutes>
          )
        },
        {
          path: "/edit-product/:id",
          element: (
            <ProtectedRoutes>
              <EditProduct />
            </ProtectedRoutes>
          )
        },
        {
          path: "/view-suggested/:id",
          element: (
            <ProtectedRoutes>
              <ViewSuggested />
            </ProtectedRoutes>
          )
        },
        {
          path: "/addProduct",
          element: (
            <ProtectedRoutes>
              <AddProduct />
            </ProtectedRoutes>
          )
        },
        {
          path: "/brands",
          element: (
            <ProtectedRoutes>
              <Brands />
            </ProtectedRoutes>
          )
        },
        {
          path: "/suggested-products",
          element: (
            <ProtectedRoutes>
              <SuggestedProducts />
            </ProtectedRoutes>
          )
        },
        {
          path: "/keywords",
          element: (
            <ProtectedRoutes>
              <Additive_Codes />
            </ProtectedRoutes>
          )
        },

        {
          path: "/reviws",
          element: (
            <ProtectedRoutes>
              <Reviews />
            </ProtectedRoutes>
          )
        },
        {
          path: "/complaints-messages",
          element: (
            <ProtectedRoutes>
              <Complaints />
            </ProtectedRoutes>
          )
        }
      ]
    },

    {
      path: "/admin/",
      element: <Component.Auth />,
      children: [{ path: "login", element: <Component.Login /> }]
    }
  ]);
  return (
    <div>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: " Arial, Helvetica, sans-serif",
            textTransform: "capitalize",
            zIndex: "9999",
            // background: '#000',
            // color: '#fff',
            borderRadius: "10px",
            boxShadow: "10px 10px 10px rgba(188, 188, 188, 0.16)",
            background: "#fff",
            color: "#000"
          }
        }}
        containerStyle={{
          bottom: 50
        }}
      />

      <VenderContext>
        <RouterProvider router={root} />
      </VenderContext>
    </div>
  );
}

export default App;
