import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import Dropzone from "react-dropzone";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Image } from "primereact/image";
import Joi from "joi";
import { Alert, CircularProgress } from "@mui/material";
import { VendersContext } from "../../context/Store";
import Swal from "sweetalert2";
import { BASE_URL } from "../../context/Enviroment";
import previousDay from "date-fns/previousDay/index.js";

const EditForm = () => {
  let { isLang, setIsLang } = useContext(VendersContext);
  const location = useLocation();
  const currentPage = new URLSearchParams(location.search).get("page");
  const [storesData, setStoresData] = useState([]);
  const [additive_codes, setAddetive_codes] = useState([]);
  const [insectsData, setinsectsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const fileToast = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("langChange") || "en");
  const toast = useRef(null);
  const [countries, setCountries] = useState([]);

  const { id } = useParams();
  ////console.log(id);

  const [formData, setFormData] = useState({
    barcode: "",
    name_en: "",
    name_de: "",
    name_ar: "",
    generic_name_en: "",
    generic_name_de: "",
    generic_name_ar: "",
    ingredients_text_en: "",
    ingredients_text_de: "",
    ingredients_text_ar: "",
    quantity: "",
    status: "",
    brands: [],
    stores: [],
    insects: [],
    additive_codes: [],
    ranking: null,
    front: null,
    ingredients: null,
    nutrition_facts: null,
    packaging: null,
    more_interesting: null,
    remove_images: []
  });

  //validation
  const [errors, setErrors] = useState({});

  const schema = Joi.object({
    barcode: Joi.string()
      .required()
      .label("barcode"),
    name_en: Joi.string()
      .required()
      .label("Name_EN"),
    name_de: Joi.string()
      .required()
      .label("Name_De"),
    name_ar: Joi.string()
      .required()
      .label("Name_AR")
  });

  const handleFrontImageChange = event => {
    const file = event.target.files[0];

    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      front: file
    });
  };
  const handleIngredientsImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      ingredients: file
    });
  };
  const handleNutritionFactsImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      nutrition_facts: file
    });
  };

  const handlePackagingImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      packaging: file
    });
  };

  const handleMoreInterestingImageChange = event => {
    const file = event.target.files[0];

    // Check if the file is an image
    if (!file.type.startsWith("image/")) {
      return;
    }

    setFormData({
      ...formData,
      more_interesting: file
    });
  };
  // get the product details data
  async function getAdditiveCodes() {
    try {
      const token = localStorage.getItem("userToken");
      const langChange = localStorage.getItem("langChange");
      const headers = {
        Authorization: `Bearer ${token}`,
        lang: langChange || "en"
      };
      // add support for additional languages
      if (langChange === "de") {
        headers.lang = "de";
      } else if (langChange === "ar") {
        headers.lang = "ar";
      }

      const response = await axios.get(`${BASE_URL}/dashboard/additive-codes/all`, { headers });
      const data = response.data;
      setAddetive_codes(data.data);
    } catch (error) {
      console.error(error);
    }
  }

  //get all countries
  async function getCountriesData() {
    try {
      const token = localStorage.getItem("userToken");
      const langChange = localStorage.getItem("langChange");
      const headers = {
        Authorization: `Bearer ${token}`,
        lang: langChange || "en"
      };
      // add support for additional languages
      if (langChange === "de") {
        headers.lang = "de";
      } else if (langChange === "ar") {
        headers.lang = "ar";
      }

      const response = await axios.get(`${BASE_URL}/dashboard/countries`, { headers });
      const data = response.data;
      //console.log(data.data.data);
      setCountries(data.data.data);
    } catch (error) {
      console.error(error);
    }
  }
  const [status, setStatus] = useState("");

  useEffect(() => {
    getAdditiveCodes();
    getCountriesData();
    const token = localStorage.getItem("userToken");

    const headers = {
      Authorization: `Bearer ${token}`,
      lang: selectedLanguage
    };

    axios.get(`${BASE_URL}/dashboard/products/${id}`, { headers }).then(res => {
      const productData = res.data.data;

      setStatus(productData.status);

      const images = productData.images.map(image => ({
        type: image.type,
        image: image.image
      }));

      setFormData({
        ...formData,
        name_en: productData.translationsArray.en.name,
        name_ar: productData.translationsArray.ar.name,
        name_de: productData.translationsArray.de.name,
        generic_name_en: productData.translationsArray.en.generic_name,
        generic_name_ar: productData.translationsArray.ar.generic_name,
        generic_name_de: productData.translationsArray.de.generic_name,
        ingredients_text_en: productData.translationsArray.en.ingredients_text,
        ingredients_text_ar: productData.translationsArray.ar.ingredients_text,
        ingredients_text_de: productData.translationsArray.de.ingredients_text,
        barcode: productData.barcode,
        images: images,
        additive_codes: productData.additive_codes,
        countries: productData.countries,
        brands: productData.brands,
        ranking :productData.ranking 

      });
    });
  }, []);

  const handleRankingChange = e => {
    const value = e.target.checked ? 1 : 0;
    setFormData(prevFormData => ({
      ...prevFormData,
      ranking: value
    }));
};



  const navigate = useNavigate();
  ////console.log(location?.state?.currentPage);

  const handleSubmit = event => {
    event.preventDefault();
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const updatedErrors = {};
      error.details.forEach(detail => {
        const field = detail.path[0];
        updatedErrors[field] = detail.message;
      });
      setErrors(updatedErrors);
    }

    // Remove language-specific fields from the formData object
    const {
      name_en,
      name_de,
      name_ar,
      generic_name_en,
      generic_name_de,
      generic_name_ar,
      ingredients_text_en,
      ingredients_text_de,
      ingredients_text_ar,
      ...formDataWithoutLanguageFields
    } = formData;

    // Create language-specific objects for generic_name and ingredients_text
    const genericNameObject = {
      en: formData.generic_name_en,
      ar: formData.generic_name_ar,
      de: formData.generic_name_de
    };

    const ingredientsTextObject = {
      en: formData.ingredients_text_en,
      ar: formData.ingredients_text_ar,
      de: formData.ingredients_text_de
    };
    const nameObject = {
      en: formData.name_en,
      ar: formData.name_ar,
      de: formData.name_de
    };
    const updatedFormData = {
      ...formDataWithoutLanguageFields,
      brands: formData.brands.map(brand => brand.id),
      stores: formData.stores.map(store => store.id),
      insects: formData.insects.map(insect => insect.id),
      additive_codes: formData.additive_codes.map(additive_codes => additive_codes.id),
      status: status,
      ranking: formData.ranking,
      remove_images: removedImages,
      front: formData.front,
      ingredients: formData.ingredients,
      nutrition_facts: formData.nutrition_facts,
      packaging: formData.packaging,
      more_interesting: formData.more_interesting,
      generic_name: genericNameObject,
      ingredients_text: ingredientsTextObject,
      name: nameObject
    };

    const token = localStorage.getItem("userToken");

    axios
      .post(`${BASE_URL}/dashboard/products/update/${id}`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "The Form Update Successfully",
          showConfirmButton: false,
          timer: 1500
        });
        //console.log("After toast show");
        navigate(`/products?page=${currentPage}`);
      })
      .catch(err => {
        // Failure
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Failed to update the form",
          showConfirmButton: false,
          timer: 2000
        });
        console.error(err);
      });
  };

  const handleStatusChange = event => {
    setStatus(event.target.value);
  };
  // dropzone function
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setselectedFiles(files);
  }

  // Add a function to handle image updates
  const handleImageUpdate = type => event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageData = {
        type: type,
        image: reader.result
      };
      // Update the formData with the new image data
      setFormData(prevFormData => ({
        ...prevFormData,
        images: prevFormData.images.map(image => (image.type === type ? imageData : image))
      }));
    };
    reader.readAsDataURL(file);
  };

  const onUpload = () => {
    fileToast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded" });
  };

  const [removedImages, setRemovedImages] = useState([]);

  const handleRemoveImage = type => {
    setRemovedImages([...removedImages, type]);

    const updatedImages = formData.images.filter(image => image.type !== type);
    setFormData({ ...formData, images: updatedImages });
  };

  const handleRemoveFrontImage = () => {
    setRemovedImages([...removedImages, "front"]);

    const updatedImages = formData.images.filter(image => image.type !== "front");
    setFormData({ ...formData, images: updatedImages });
  };
  return (
    <>
      <Toast ref={toast} />
      <form onSubmit={handleSubmit} className="p-5 d-flex gap-4">
        <div className="col-md-9">
          <TabView className="card p-2 ">
            <TabPanel header={isLang === "en" ? "DE Name" : "اللغة الالمانية"}>
              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="name_en"> {isLang === "en" ? "Name_De: " : "الاسم:"}</label>{" "}
                  <InputText
                    className="w-100"
                    name="name_de"
                    id="name_de"
                    onChange={e => setFormData({ ...formData, name_de: e.target.value })}
                    value={formData.name_de}
                  />
                  {errors.name_de && (
                    <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                      {errors.name_de}
                    </Alert>
                  )}
                </div>
                <div className="w-100">
                  <label htmlFor="generic_name_de"> {isLang === "en" ? "Generic Name DE" : "الاسم الشائع:"}</label>
                  <InputText
                    className="w-100"
                    name="generic_name_de"
                    id="generic_name_de"
                    onChange={e => setFormData({ ...formData, generic_name_de: e.target.value })}
                    value={formData.generic_name_de}
                  />
                </div>
              </div>

              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="ingredients_text_en">{isLang === "en" ? "ingredients_text_de" : "المكونات:"}</label>
                  <textarea
                    className="w-100"
                    name="ingredients_text_de"
                    id="ingredients_text_de"
                    onChange={e => setFormData({ ...formData, ingredients_text_de: e.target.value })}
                    value={formData.ingredients_text_de}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel header={isLang === "en" ? "EN Name" : "اللغة الانجليزية"}>
              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="name_en"> {isLang === "en" ? "EN_Name:" : "الاسم:"}</label>{" "}
                  <InputText
                    className="w-100"
                    name="name_en"
                    id="name_en"
                    onChange={e => setFormData({ ...formData, name_en: e.target.value })}
                    value={formData.name_en}
                  />
                  {errors.name_en && (
                    <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                      {errors.name_en}
                    </Alert>
                  )}
                </div>
                <div className="w-100">
                  <label htmlFor="generic_name_en">{isLang === "en" ? "Generic Name EN:" : "الاسم الشائع:"}</label>
                  <InputText
                    className="w-100"
                    name="generic_name_en"
                    id="generic_name_en"
                    onChange={e => setFormData({ ...formData, generic_name_en: e.target.value })}
                    value={formData.generic_name_en}
                  />
                </div>
              </div>

              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="ingredients_text_en"> {isLang === "en" ? "ingredients_text_en" : "المكونات:"}</label>
                  <textarea
                    className="w-100"
                    name="ingredients_text_en"
                    id="ingredients_text_en"
                    onChange={e => setFormData({ ...formData, ingredients_text_en: e.target.value })}
                    value={formData.ingredients_text_en}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel header={isLang === "en" ? "AR Name" : "اللغة العربية"}>
              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="name_ar">{isLang === "en" ? "AR_Name" : " الاسم:"}</label>{" "}
                  <InputText
                    className="w-100"
                    name="name_ar"
                    id="name_ar"
                    onChange={e => setFormData({ ...formData, name_ar: e.target.value })}
                    value={formData.name_ar}
                  />
                  {errors.name_ar && (
                    <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                      {errors.name_ar}
                    </Alert>
                  )}
                </div>
                <div className="w-100">
                  <label htmlFor="generic_name_ar">{isLang === "en" ? "Generic Name AR" : "الاسم الشائع:"}</label>
                  <InputText
                    className="w-100"
                    name="generic_name_ar"
                    id="generic_name_ar"
                    onChange={e => setFormData({ ...formData, generic_name_ar: e.target.value })}
                    value={formData.generic_name_ar}
                  />
                </div>
              </div>
              <div className="d-flex gap-4 mb-3">
                <div className="w-100">
                  <label htmlFor="ingredients_text_ar">{isLang === "en" ? "ingredients_text_AR" : "المكونات:"}</label>
                  <textarea
                    className="w-100"
                    name="ingredients_text_ar"
                    id="ingredients_text_ar"
                    onChange={e => setFormData({ ...formData, ingredients_text_ar: e.target.value })}
                    value={formData.ingredients_text_ar}
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>

          <div className="d-flex gap-4 mb-3 mt-3">
            <div className="w-100">
              <label htmlFor="barcode"> {isLang === "en" ? "Product barcode" : " كود المنتج:"}</label>
              <InputText
                className="w-100"
                name="barcode"
                id="barcode"
                onChange={e => setFormData({ ...formData, barcode: e.target.value })}
                value={formData.barcode}
              />
              {errors.barcode && (
                <Alert severity="error" sx={{ paddingTop: "0", paddingBottom: "0", marginTop: "10px" }}>
                  {errors.barcode}
                </Alert>
              )}
            </div>

            <div className="w-100">
              <label htmlFor="additive_codes">{isLang === "en" ? "additive codes" : " الأكواد المضافة:"}</label>

              <MultiSelect
                optionLabel="name"
                filter
                placeholder="اختر من الأكواد المضافة"
                display="chip"
                id="additive_codes"
                value={formData.additive_codes}
                maxSelectedLabels={3}
                className="w-100 md:w-20rem"
                options={additive_codes}
                onChange={e => setFormData({ ...formData, additive_codes: e.target.value })}
              />
            </div>
          </div>

          <div className="d-flex gap-4 mb-3">
            <div className="w-100">
              <label htmlFor="countries">{isLang === "en" ? "countries" : " الدول:"}</label>
              <MultiSelect
                optionLabel="name"
                display="chip"
                filter
                placeholder="Select countries"
                id="countries"
                maxSelectedLabels={3}
                className="w-100 md:w-20rem"
                options={countries}
                value={formData.countries}
                onChange={e => setFormData({ ...formData, countries: e.target.value })}
              />
            </div>
            <div className="w-100">
              <label htmlFor="stores">{isLang === "en" ? "brands" : " الماركات:"}</label>
              <MultiSelect
                optionLabel="name"
                filter
                display="chip"
                placeholder="Select brands"
                id="brands"
                maxSelectedLabels={3}
                className="w-100 md:w-20rem"
                options={brandsData}
                value={formData.brands}
                onChange={e => setFormData({ ...formData, brands: e.target.value })}
              />
            </div>
          </div>

          <div className="images-card card p-4 my-4 pt-4 mb-4">
            {/* First image */}
            <div className="d-flex justify-content-between mb-5">
              <h4 htmlFor="ingredients">{isLang === "en" ? "Ingredients Image:" : " صورة المكونات:"}</h4>
              <input type="file" name="ingredients" onChange={handleIngredientsImageChange} />

              {formData?.images?.map(
                (image, index) =>
                  image.type === "ingredients" && (
                    <div key={index} className="d-flex gap-3 align-items-center">
                      <Image preview width="150px" height="150px" src={image.image} alt={`Image ${index}`} />
                      <Button
                        icon="pi pi-trash"
                        rounded
                        outlined
                        severity="danger"
                        onClick={() => handleRemoveImage("ingredients")}
                      />
                    </div>
                  )
              )}
            </div>

            {/* Second image */}
            <div className="d-flex justify-content-between mb-5">
              <h4 htmlFor="nutrition">{isLang === "en" ? "Nutrition Image:" : " صورة التغذية:"}</h4>
              <input type="file" name="nutrition" onChange={handleNutritionFactsImageChange} />

              {formData?.images?.map(
                (image, index) =>
                  image.type === "nutrition_facts" && (
                    <div key={index} className="d-flex gap-3 align-items-center">
                      <Image preview width="150px" height="150px" src={image.image} alt={`Image ${index}`} />
                      <Button
                        icon="pi pi-trash"
                        rounded
                        outlined
                        severity="danger"
                        onClick={() => handleRemoveImage("nutrition_facts")}
                      />
                    </div>
                  )
              )}
            </div>

            {/* Third image */}
            <div className="d-flex justify-content-between mb-5">
              <h4 htmlFor="packaging">{isLang === "en" ? "Packaging Image:" : " صورة التغليف:"}</h4>
              <input type="file" name="packaging" onChange={handlePackagingImageChange} />

              {formData?.images?.map(
                (image, index) =>
                  image.type === "packaging" && (
                    <div key={index} className="d-flex gap-3 align-items-center">
                      <Image preview width="150px" height="150px" src={image.image} alt={`Image ${index}`} />
                      <Button
                        icon="pi pi-trash"
                        rounded
                        outlined
                        severity="danger"
                        onClick={() => handleRemoveImage("packaging")}
                      />
                    </div>
                  )
              )}
            </div>

            {/* Fourth image */}
            <div className="d-flex justify-content-between mb-5">
              <h4 htmlFor="interested">{isLang === "en" ? "More Image:" : " صورة اضافية:"}</h4>
              <input type="file" name="interested" onChange={handleMoreInterestingImageChange} />

              {formData?.images?.map(
                (image, index) =>
                  image.type === "more_interesting" && (
                    <div key={index} className="d-flex gap-2 align-items-center">
                      <Image preview width="150px" height="150px" src={image.image} alt={`Image ${index}`} />
                      <Button
                        icon="pi pi-trash"
                        rounded
                        outlined
                        severity="danger"
                        onClick={() => handleRemoveImage("more_interesting")}
                      />
                    </div>
                  )
              )}
            </div>
          </div>

          <div className="d-flex gap-3 justify-content-start p-dialog-footer">
            <Button
              className="mt-4 d-flex justify-content-center align-items-center"
              type="submit"
              label={isLang === "en" ? "Edit Product" : "تعديل المنتج"}
              icon="pi pi-check"
            />
            <Link to="/products">
              <Button
                className="mt-4 d-flex justify-content-center align-items-center p-button-secondary"
                type="submit"
                label={isLang === "en" ? "Cancel" : "إلغاء"}
              />
            </Link>
          </div>
        </div>
        <div className="col-md-3 px-3 card right-card pt-5 h-50">
          <div className="product-status d-flex justify-content-around">
            <input
              type="radio"
              className="btn-check"
              name="status"
              id="MUSHBOOH-outlined"
              value="MUSHBOOH"
              checked={status === "MUSHBOOH"}
              onChange={handleStatusChange}
              autoComplete="off"
            />
            <label className="btn btn-outline-warning status-label" htmlFor="MUSHBOOH-outlined">
              {isLang === "en" ? "MUSHBOOH" : "مشبوه"}
            </label>

            <input
              type="radio"
              className="btn-check"
              name="status"
              id="HALAL-outlined"
              value="HALAL"
              checked={status === "HALAL"}
              onChange={handleStatusChange}
              autoComplete="off"
            />
            <label className="btn btn-outline-success status-label" htmlFor="HALAL-outlined">
              {isLang === "en" ? "HALAL" : " حلال"}
            </label>

            <input
              type="radio"
              className="btn-check"
              name="status"
              id="HARAM-outlined"
              value="HARAM"
              checked={status === "HARAM"}
              onChange={handleStatusChange}
              autoComplete="off"
            />
            <label className="btn btn-outline-danger status-label" htmlFor="HARAM-outlined">
              {isLang === "en" ? "HARAM" : "حرام"}
            </label>
          </div>
          <div className="card p-3 pb-3 justify-content-between mt-3 d-flex flex-row align-items-center">
            <h6 className="ml-2">{isLang === "en" ? " Top 10 products " : " اخر 10 منتجات"}</h6>
            <Checkbox
              name="category"
              value={formData?.ranking}
              onChange={handleRankingChange}
              checked={formData.ranking == "1"}
            />
          </div>
          <div className="pt-3">
            <div className="card p-2 mb-4 front-image">
              <h4 className="mb-2">{isLang === "en" ? " Main Image: " : " الصورة الاساسية:  "} </h4>
              <input type="file" className="mb-3" onChange={handleFrontImageChange} />

              {formData?.images?.map(
                (image, index) =>
                  image.type === "front" && (
                    <div key={index} className="d-flex gap-2 align-items-center">
                      <Image preview height="250px" width="230px" src={image.image} alt={`Image ${index}`} />
                      <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={handleRemoveFrontImage} />
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditForm;
